@import 'variables.scss';

@mixin header($size) {
	@if $size==desktop {
		font-family: Poppins;
		font-style: normal;
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		color: $accent-color;
	}

 @else if $size==mobile {
		@media (width <= 767px) {
			font-size: 20px;
			line-height: 30px;
		}
	}

 @else if $size==onlyMobile {
		@include header(desktop);
		@include header(mobile);
	}
}

@mixin text($size) {
	@if $size==desktop {
		font-family: Poppins;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		color: $accent-color;
	}

 @else if $size==mobile {
		@media (width <= 767px) {
			font-size: 16px;
			line-height: 22px;
		}
	}

 @else if $size==onlyMobile {
		@include text(desktop);
		@include text(mobile);
	}
}

@mixin grayText($size) {
	@if $size==desktop {
		@include text(desktop);
		color: $gray-color;
	}

 @else if $size==mobile {
		@media (width <= 767px) {
			@include text(mobile);
			color: $gray-color;
		}
	}

 @else if $size==onlyMobile {
		@include grayText(desktop);
		@include grayText(mobile);
	}
}

@mixin link($size) {
	@if $size==desktop {
		@include text(desktop);
		text-decoration: none;
		color: $link-color;
	}

 @else if $size==mobile {
		@media (width <= 767px) {
			@include text(mobile);
			color: $link-color;
		}
	}

 @else if $size==onlyMobile {
		@include button(desktop);
		@include button(mobile);
	}
}

@mixin button($size) {
	@if $size==desktop {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 24px;
		width: 100%;
		min-height: 38px;
		background: $accent-color;
		border-radius: 8px;
		font-size: 12px;
		line-height: 18px;
		color: #fff;
		border: 0px;

		cursor: pointer;
		text-decoration: none;

		font-family: Poppins, sans-serif;
	}

 @else if $size==mobile {
		@media (width <= 767px) {
			min-height: 56px;
			border-radius: 20px;
			font-size: 16px;
			line-height: 22px;
		}
	}

 @else if $size==onlyMobile {
		@include button(desktop);
		@include button(mobile);
	}
}

@mixin textInput($size) {
	@if $size==desktop {
		font-family: Poppins, sans-serif;
		font-size: 12px;
		line-height: 18px;
		display: flex;
		align-items: center;
		color: $accent-color;
		background: $light-gray-color;
		border: 1px solid $border-color;
		border-radius: 6px;
		padding: 6px 0px 6px 8px;
		width: 100%;
		height: 30px;
		box-sizing: border-box;
		outline: 0px;

		&:not(:placeholder-shown) {
			background-color: white;
		}

		&::placeholder {
			color: $gray-color;
		}

		&:focus,
		&:active {
			border-color: $link-color;
		}
	}

 @else if $size==mobile {
		@media (width <= 767px) {
			font-size: 16px;
			line-height: 22px;
			height: 44px;
			padding: 11px 0px 11px 10px;
		}
	}
}

@mixin checkboxInput($size) {
	@if $size==desktop {
		appearance: none;
		display: inline-block;
		min-width: 16px;
		width: 16px;
		height: 16px;
		border: 1.7px solid $border-color;
		border-radius: 4px;

		background: #fff;
		margin: 0px;

		cursor: pointer;
	}

 @else if $size==mobile {
		@media (width <= 767px) {
			width: 24px;
			height: 24px;
			border-width: 1.3px;
		}
	}
}

@mixin inputError {
	> span.error {
		display: block;
		font-size: 10px;
		line-height: 12px;
		height: 12px;
		color: $error-color;
		font-family: 'Microsoft Sans Serif';
		margin-top: 4px;
	}

	&.with_error {
		> input {
			border-color: $error-color;
		}
	}
}
