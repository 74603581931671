@import 'variables.scss';

.headerbar_container {
	.headerbar {
		width: 100%;
		height: 60px;
		box-sizing: border-box;
		padding: 0px 24px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		> .left {
			flex: 1;

			img.logo {
				max-height: 32px;
			}
		}

		> .center {
			img.logo {
				width: 106px;
			}
		}

		> .right {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;

			> .btn_book {
				padding: 10px 24px;
				margin-left: 16px;

				background: #f5f7fa;
				border-radius: 8px;

				font-family: Poppins;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;

				color: #3b506c;
				text-decoration: none;
				box-sizing: border-box;
				text-align: center;
				white-space: nowrap;

				transition: background 150ms ease-in-out;

				&:hover {
					background: #e4e9f1;
				}
			}

			> .sign_in {
				padding: 10px 24px;
				margin-left: 16px;

				background: #f5f7fa;
				border-radius: 8px;

				font-family: Poppins;
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 18px;

				color: #3b506c;
				text-decoration: none;
				box-sizing: border-box;
				text-align: center;
				white-space: nowrap;

				transition: background 150ms ease-in-out;

				&:hover {
					background-color: #e4e9f1;
				}
			}

			> .registered {
				position: relative;
				width: 40px;
				height: 40px;

				margin-left: 16px;

				text-decoration: none;

				.photo {
					width: 40px;
					height: 40px;
					margin-right: 10px;
					border-radius: 50%;
				}

				span {
					@extend .photo;
					background: $accent-color;
					color: white;

					&::before {
						display: flex;
						justify-content: center;
						line-height: 40px;

						font-family: Poppins;
						font-style: normal;
						font-weight: 400;
						font-size: 12px;

						content: attr(data-name);
						width: 40px;
						height: 40px;

						background: $accent-color;
						color: white;

						transition: background 150ms ease-in-out;

						border-radius: 50%;
						text-align: center;
					}

					&:hover {
						&::before {
							background: $accent-hover-color;
						}
					}
				}
			}

			> .contact_us {
				height: 40px;
				width: 40px;

				display: flex;
				align-items: center;
				justify-content: center;

				background-color: #f5f7fa;
				border-radius: 50%;

				font-family: poppins, sans-serif;
				font-size: 18px;

				color: #3b506c;
				border: none;
				cursor: pointer;

				margin-left: 16px;

				&.active {
					background-color: rgb(59 80 108);
					color: white;
				}

				transition: background 150ms ease-in-out;

				&:hover {
					background-color: #e4e9f1;
				}
			}

			> .calendar {
				height: 40px;
				width: 40px;

				border-radius: 50%;
				background: url('../../public/img/calendar.svg') center center no-repeat;

				background-color: #f5f7fa;
				transition: background 150ms ease-in-out;

				&:hover {
					background-color: #e4e9f1;
				}

				&.active {
					background-image: url('../../public/img/calendar_active.svg');
					background-color: #3b506c !important;
				}
			}
		}
	}
}

@media (width <= 767px) {
	.headerbar_container {
		height: 48px;

		.headerbar {
			width: 100vw;
			height: 48px;
			position: fixed;
			top: 0;
			z-index: 500;

			border-bottom: 1px solid #f5f7fa;
			padding: 0px 20px;
			background: white;

			> .left {
				display: flex;
				align-items: center;

				img.logo {
					width: 76px;
				}
			}

			> .right {
				> .btn_book {
					padding: 6px 12px;
					border-radius: 12px;
				}
			}

			.burger_button {
				width: 20px;
				height: 11px;

				position: relative;
				z-index: 501;

				background: transparent;
				border: none;

				cursor: pointer;

				.line {
					height: 1px;
					width: 100%;

					position: absolute;
					right: 0;

					background-color: rgb(59 80 108);
					transition: all 0.3s ease;

					&:nth-child(1) {
						top: 0px;
					}

					&:nth-child(2) {
						top: 5px;
					}

					&:nth-child(3) {
						top: 10px;
					}
				}

				&.active {
					.line {
						&:nth-child(1) {
							top: 5px;
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							opacity: 0;
						}

						&:nth-child(3) {
							top: 5px;
							transform: rotate(-45deg);
						}
					}
				}
			}

			.overlay {
				height: 100vh;
				width: 100vw;
				box-sizing: border-box;
				opacity: 0;

				display: flex;
				flex-direction: column;

				position: fixed;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;

				background: white;
				padding: 48px 0 0;

				transition: opacity ease-in-out 0.3s;
				pointer-events: none;

				> nav {
					height: 100%;

					display: flex;
					flex-direction: column;

					border-top: 1px solid #f5f7fa;
					padding: 20px 20px 34px;

					h2 {
						font-family: Poppins;
						font-style: normal;
						font-weight: 400;
						font-size: 20px;
						line-height: 30px;
						color: rgb(59 80 108);
						height: 60px;

						display: flex;
						align-items: center;
						text-align: center;

						position: relative;

						margin: 0 0 40px;
						padding-left: 80px;

						&::before {
							content: '';

							width: 60px;
							height: 60px;

							position: absolute;
							top: 0;
							left: 0;

							background: url('../../public/img/guest.svg') center center no-repeat;
						}

						&.registered::before {
							font-family: Poppins;
							font-weight: 400;
							font-style: normal;
							font-size: 18px;

							content: attr(data-name);

							background: rgb(59 80 108);
							color: white;
							border-radius: 50%;
							text-align: center;
							line-height: 60px;
						}
					}

					.list {
						width: 100%;

						display: flex;
						justify-content: space-between;
						flex-direction: column;
						row-gap: 20px;

						padding: 0;
						margin: 0;
						list-style: none;

						li {
							font-family: Poppins;
							font-style: normal;
							font-weight: 400;
							font-size: 20px;
							line-height: 30px;
							color: rgb(59 80 108);
						}
					}

					div {
						font-family: Poppins;
						font-style: normal;
						font-weight: 400;
						font-size: 20px;
						line-height: 30px;
						color: #14a2e2;

						margin-top: auto;
					}
				}

				&.open {
					opacity: 1;
					pointer-events: all;
				}
			}
		}
	}
}
