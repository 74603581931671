.dashboard {
	.profile_info {
		.header_block {
			margin-bottom: 40px;
		}

		form {
			.input > input {
				margin-bottom: 29px;
			}

			.photo {
				margin-top: 14px;

				label {
					margin-top: 15px;
					margin-bottom: 36px;
					width: 80px;
					height: 80px;
					cursor: pointer;
					opacity: 100%;
				}

				.image {
					border-radius: 44px;
					width: 100%;
					height: 100%;
					color: $white;
					font-size: 28px;
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					overflow: hidden;

					.initials,
					.action {
						position: absolute;
						opacity: 0;
					}

					.initials {
						display: none;
					}
				}

				&.with_photo {
					.preview {
						width: 80px;
						height: 80px;
						border-radius: 44px;
						overflow: hidden;
						background-size: 80px 80px;
					}

					.image {
						&:hover {
							background: linear-gradient(0deg, $scrollbar-color, $scrollbar-color);

							.action {
								opacity: 100%;
								background: linear-gradient(0deg, $scrollbar-color, $scrollbar-color);
								width: 100%;
								height: 100%;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
					}
				}

				&.without_photo {
					.image {
						background: $accent-color;

						.initials {
							display: block;
							opacity: 100%;
						}

						&:hover {
							.initials {
								opacity: 0;
							}

							.action {
								opacity: 100%;
							}
						}
					}
				}

				a {
					width: 20px;
					height: 20px;
					position: absolute;
					top: 0;
					right: 0;
					background: url('#{$img-url}/close_round_light.svg') center center no-repeat;
					background-color: $light-blue-color;
				}
			}

			.change_password {
				display: flex;
				flex-direction: column;
				padding-bottom: 40px;
				gap: 16px;

				div {
					input {
						margin-bottom: 0;

						&::placeholder {
							font-size: 12px;
							padding-left: 2px;
						}
					}

					.toggler {
						width: 24px;
						height: 24px;
						display: block;
						background: url('#{$img-url}/icon_eye_cross_big.svg') center center no-repeat;

						cursor: pointer;
						position: absolute;
						right: 10px;
						top: 8px;
						text-decoration: none;

						&.toggled {
							background-image: url('#{$img-url}/icon_eye_big.svg');
						}
					}
				}
			}
		}
	}
}
