.dashboard {
	.dashboard_list.notifications_list {
		.header_block {
			flex-wrap: nowrap;
			align-items: center;
			margin-bottom: 24px;
			display: grid;
			grid-template-columns: min-content 40px max-content;
			grid-template-areas: 'header counter checkbox';

			h1 {
				width: auto;
				margin-bottom: 0;
				margin-right: 5px;
				grid-area: header;
				justify-self: stretch;
			}

			.counter {
				grid-area: counter;
				justify-self: left;
				margin-left: 3px;
			}

			a {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.checkbox {
				grid-area: checkbox;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 22px;
				cursor: pointer;

				input {
					@include checkboxInput(desktop);
					border-color: $scrollbar-color;

					&.checked {
						border-color: $accent-color;
						background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
					}
				}

				label {
					font-size: 14px;
					line-height: 21px;
					opacity: 50%;
					padding-left: 8px;
				}
			}
		}

		.no_items {
			padding: 0;
		}

		.list {
			margin-bottom: 0;

			.info {
				min-width: 320px;
			}

			.actions {
				display: flex;

				.icon {
					width: 40px;
					height: 40px;
				}

				.arrow_right_requests {
					margin-left: 8px;
				}
			}
		}
	}
}
