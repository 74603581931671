.asset_calendar {
	margin-top: 40px;

	.header {
		margin-bottom: 16px;

		display: flex;

		align-items: center;

		h4 {
			font-size: 24px;
			line-height: 32px;
		}

		.month_picker {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: $white;
			border: 1px solid $scrollbar-color;
			border-radius: 8px;
			margin-left: 36px;
			height: 40px;
			box-sizing: border-box;
			width: 220px;

			span {
				padding: 10px 16px;
				font-size: 14px;
				line-height: 21px;
			}

			a {
				padding: 4px;
				width: 20px;
				height: 20px;
			}

			.arrow_left {
				background: url('#{$img-url}/arrow_left_stepper.svg') center no-repeat;
			}

			.arrow_right {
				background: url('#{$img-url}/arrow_left_stepper.svg') center no-repeat;
				transform: scaleX(-1);
			}

			a.disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	.list {
		display: grid;
		grid-template-columns: min-content 1fr;

		&.items {
			grid-template-columns: min-content min-content 1fr;
		}
		gap: 16px;
		grid-column-gap: 24px;

		font-size: 12px;

		.title {
			opacity: 0.5;
		}

		.date {
			white-space: nowrap;
		}

		.empty {
			white-space: nowrap;
		}

		a {
			color: #14a2e2;
		}
	}
}
