$accent-color: #3b506c;
$accent-hover-color: #4a5e78;
$link-color: #14a2e2;
$gray-color: #898f8f;
$light-gray-color: #fcfcfc;
$border-color: #e7e7e7;
$error-color: #db6b83;

$light-blue-color: #f5f7fa;
$white: #fff;

$hover-dropdown-color: #f5f7fa;
$scrollbar-color: #3b506c80;
$top-warning-color: #faf0f3;
$background-accent: #ebf1fa;
$pending-color: #f19eca;
$session-color: #556e8f;

$diagram-colors: (
	1: #b9d6f2,
	2: #629ed6,
	3: #0079d0,
	4: #0353a4,
	5: #417fb1,
	6: #3b506c,
	7: #061a40,
);

$img-url: '../../public/img';
$fonts-url: '../../public/fonts';

$calendar-cell: 35px;
