.app2 {
	.dates_times {
		width: 674px;
		box-sizing: border-box;

		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;

		margin: 42px auto 0;

		.btn_next {
			&.mobile {
				display: none;
			}
		}

		.sum {
			@include grayText(desktop);

			h4 {
				@include header(desktop);
				margin: 0 0 16px;
			}

			.rooms {
				margin: 0;
				padding: 0;

				li {
					display: flex;
					justify-content: space-between;

					margin-bottom: 8px;
					text-decoration: none;

					div:nth-child(2) {
						white-space: nowrap;
					}
				}
			}

			.output_wrap {
				display: flex;
				justify-content: space-between;

				margin-top: 16px;
				padding-top: 8px;
				border-top: 1px solid $border-color;
			}
		}

		.add_options {
			h4 {
				@include header(desktop);
				margin: 0 0 16px;
			}

			.dropdowns {
				margin: 0;
				padding: 0;

				display: flex;
				flex-direction: column;
				gap: 8px;

				.row {
					display: flex;
					align-items: center;

					text-decoration: none;

					width: 100%;
					gap: 8px;

					.dropdown {
						&.calendar {
							width: 125px;
						}

						&.time {
							flex-grow: 2;
						}
					}

					.addition {
						display: none;

						.mobile_info,
						.btn_close {
							display: none;
						}

						.btn_close {
							width: 10px;
							height: 10px;
							background: url('#{$img-url}/close.svg') center no-repeat;
							margin: -8px;
							padding: 8px;
						}
					}
				}
			}

			.btn_add {
				display: none;

				@include button(desktop);
				padding: 0;
				margin: 24px 0 0;

				color: $accent-color;
				background-color: $light-blue-color;
			}

			.btn_next {
				&.desktop {
					@include button(desktop);
					padding: 0;
					margin: 8px 0 0;

					opacity: 0.5;
					pointer-events: none;
				}
			}
		}

		&.selected {
			.btn_next.mobile {
				opacity: 1;
				pointer-events: all;
			}

			.add_options {
				.btn_add {
					display: flex;
				}

				.btn_next {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		&.several {
			.add_options {
				.dropdowns {
					.row {
						.addition {
							display: block;

							.btn_close {
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.dates_times {
			height: calc(100vh - 48px);
			width: 100%;
			margin: 0;
			padding: 20px;
			display: flex;
			flex-direction: column-reverse;
			gap: 0;

			.btn_next {
				&.mobile {
					@include button(onlyMobile);
					padding: 0;
					margin: 16px 0 0;
				}

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			.sum {
				width: 100%;
				margin: auto 0 0;

				@include grayText(mobile);

				display: flex;
				justify-content: space-between;

				h4,
				.rooms {
					display: none;
				}

				.output_wrap {
					width: 100%;
					margin: 0;
					padding: 0;
					border: none;
				}
			}

			.add_options {
				h4 {
					@include header(mobile);
					margin-bottom: 20px;
				}

				.dropdowns {
					.row {
						flex-direction: column;

						.dropdown {
							width: 100% !important;

							&.calendar {
								margin: 0 0 12px;
							}

							&.time {
								margin-right: 0;
							}
						}

						.addition {
							display: block;

							.btn_close {
								width: 12px;
								height: 12px;
								margin: -16px;
								padding: 16px;
								margin-right: -10px;
							}
						}
					}
				}

				.btn_add {
					@include link(mobile);
					margin-top: 20px;
				}

				.btn_next {
					&.desktop {
						display: none;
					}
				}
			}

			&.several {
				.add_options {
					.dropdowns {
						.row {
							margin-top: 20px;

							.addition {
								width: 100%;
								display: flex;
								justify-content: space-between;
								align-items: center;

								order: -1;
								margin-bottom: 14px;

								.mobile_info {
									display: block;

									@include grayText(onlyMobile);
								}
							}
						}
					}
				}
			}
		}
	}
}
