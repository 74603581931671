body {
	margin: 0;
}

.app2 {
	.header {
		height: 60px;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.info {
			width: 167px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.date,
			.question {
				width: 40px;
				height: 40px;
				border-radius: 50%;
			}

			.date {
				background: $light-blue-color url('#{$img-url}/today.svg') center center no-repeat;
			}

			.question {
				background: $light-blue-color url('#{$img-url}/question.svg') center center no-repeat;
			}

			.sign_in {
				@include text(desktop);
				text-decoration: none;
				font-size: 14px;
				line-height: 21px;
			}
		}
	}

	.top_warning {
		@include text(desktop);
		text-align: center;
		padding: 10px 20px;
		background-color: $top-warning-color;
		border-radius: 8px;
		width: 674px;
		box-sizing: border-box;
		position: absolute;
		top: 11px;
		left: 50%;
		margin-left: -337px;

		transition: ease-in-out 0.3s;

		&.hidden {
			opacity: 0;
			pointer-events: none;
		}
	}

	.rooms_duration_container {
		display: flex;
		justify-content: space-between;
		width: 674px;
		margin: 42px auto 32px;
		gap: 40px;

		.rooms {
			width: 436px;

			h4 {
				@include header(desktop);
				margin: 0px 0px 16px;
			}

			ul {
				padding: 0;
				margin: 0;
			}

			li {
				display: flex;
				align-items: center;
				margin: 0px 0px 8px;

				img {
					width: 93px;
					height: 93px;
					min-width: 93px;
					min-height: 93px;
					object-fit: cover;
				}

				.info {
					min-width: 344px;
					box-sizing: border-box;
					padding: 10px 26px;

					display: flex;
					align-items: center;
					justify-content: space-between;

					.text {
						h5 {
							@include header(desktop);
							font-size: 14px;
							line-height: 21px;
							margin: 0px 0px 8px;
						}

						.duration {
							@include grayText(desktop);
							margin: 0px 0px 8px;
						}

						.details_capacity {
							@include grayText(desktop);
							margin: 0;

							a {
								@include link(desktop);
								margin: 0 12px 0 0;
							}
						}
					}
				}

				&.selected {
					background-color: $light-blue-color;
				}
			}

			.data {
				margin: 16px 0 0;
				border-top: 1px solid #e7e7e7;
				padding: 8px 0 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				a {
					@include link(desktop);
				}

				.total {
					@include grayText(desktop);
				}
			}
		}

		.duration {
			width: 100%;
			box-sizing: border-box;

			h4 {
				display: none;

				@include header(desktop);
				margin: 0px 0px 16px;
			}

			.dropdown {
				display: none;
			}

			.warning {
				@include text(desktop);
				width: 198px;
			}

			.btn_next {
				@include button(desktop);
				padding: 0;
				margin: 24px 0 0;

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}

				&.mobile_step_1 {
					display: none;
				}
			}

			.total_mobile {
				display: none;
			}
		}

		&.selected {
			.duration {
				.dropdown,
				h4 {
					display: block;
				}

				.warning {
					display: none;
				}
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.header {
			display: none;
		}

		.rooms_duration_container {
			display: block;
			margin: 20px 0 0;
			width: 100%;

			.rooms {
				width: 100%;

				h4 {
					@include header(mobile);
					margin: 0px 0px 16px;
					padding: 0 20px;
				}

				li {
					display: block;
					align-items: center;
					padding: 20px 0;
					margin: 0px 0px 8px;

					img {
						display: none;
					}

					.info {
						width: 100%;
						padding: 0 20px;

						.text {
							h5 {
								font-size: 16px;
								line-height: 22px;
								margin: 0px 0px 12px;
							}

							.duration {
								display: none;
							}

							.details_capacity {
								@include grayText(mobile);

								a {
									@include link(mobile);
									margin: 0 16px 0 0;
								}
							}
						}
					}
				}

				.data {
					margin: 40px 0 0;
					border: none;
					padding: 0 20px;

					a {
						@include link(mobile);
					}

					.total {
						@include grayText(mobile);
					}
				}
			}

			.duration {
				padding: 0 20px;

				h4,
				.dropdown,
				.warning {
					display: none !important;
				}

				.btn_next {
					@include button(mobile);
					margin: 16px 0 20px;

					&.mobile_step_1 {
						display: flex;
					}

					&.mobile_step_2 {
						display: none;
					}
				}
			}

			&.mobile_step_2 {
				height: calc(100vh - 80px);

				display: flex;
				flex-direction: column-reverse;
				gap: 0;

				.rooms {
					margin-top: auto;

					h4,
					.list {
						display: none;
					}

					.data {
						margin-top: auto;
						float: right;

						a {
							display: none;
						}

						.total {
							display: none;
						}
					}
				}

				.duration {
					height: 100%;
					display: flex;
					flex-direction: column;
					gap: 0;

					h4 {
						display: block !important;

						@include header(mobile);
					}

					.dropdown {
						display: block !important;
					}

					.btn_next {
						&.mobile_step_1 {
							display: none;
						}

						&.mobile_step_2 {
							display: flex;
						}
					}

					.total_mobile {
						display: block;
						margin-top: auto;
						text-align: right;
						padding-right: 20px;

						@include grayText(onlyMobile);
					}
				}
			}
		}

		.stepper {
			display: none;
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.top_warning {
			width: 100vw;
			margin-left: -50vw;
		}
	}
}
