.app2.contact_us {
	display: flex;
	flex-direction: column;

	.popup {
		width: 358px;
		box-sizing: border-box;
		background-color: #fff;

		border-radius: 8px;

		padding: 40px;

		position: relative;

		&.scrollable {
			max-height: calc(100vh - 60px);
			overflow-y: auto;
			overflow-x: hidden;
		}

		.btn_close {
			display: none;
		}

		h3 {
			@include header(desktop);
			margin: 0px 0px 16px;
		}

		p {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			margin-bottom: 16px;
		}

		button[type='submit'] {
			margin: 22px 0 0;
		}

		&.success {
			padding-bottom: 31px;

			.img {
				width: 24px;
				height: 24px;
				background: url('#{$img-url}/success.svg') center center no-repeat;
				margin-bottom: 4px;
			}

			h3 {
				margin-bottom: 8px;
			}

			.text {
				@include grayText(desktop);
				margin: 0;
			}

			.btn_continue {
				@include button(desktop);
				padding: 0;
				margin: 24px 0 0;

				&:disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}
		}

		&.contact_us {
			width: auto;
			min-width: 635px;

			align-self: center;
			justify-self: center;

			.btn_close {
				width: 30px;
				height: 30px;
				background-size: 30px;
			}

			.wrapper {
				display: grid;
				grid-template-columns: min-content 1fr;
				gap: 42px;

				.account_form {
					min-width: 304px;
					margin-top: 0px;

					.input {
						margin-bottom: 16px;

						> textarea {
							height: 60px;
						}

						.title {
							@include text(desktop);
							margin-bottom: 4px;
						}

						&.message {
							margin-bottom: 0;
						}
					}

					button[type='submit'] {
						margin: 24px 0 0;
					}
				}

				.info {
					margin-top: 22px;
					min-width: 196px;

					a {
						@include grayText(desktop);
						text-decoration: none;

						display: block;
						position: relative;

						margin-bottom: 18px;
						padding-left: 28px;

						&::before {
							content: '';
							width: 20px;
							height: 20px;
							position: absolute;
							top: 0;
							left: 0;
						}

						&.phone::before {
							background: url('#{$img-url}/phone.svg') center center no-repeat;
						}

						&.email::before {
							background: url('#{$img-url}/email.svg') center center no-repeat;
						}

						&.map {
							display: flex;
							flex-direction: column;

							div {
								display: inline;
								width: min-content;
								min-width: 100%;
							}

							&::before {
								background: url('#{$img-url}/map.svg') center center no-repeat;
							}
						}
					}
				}
			}

			button[type='submit'] {
				&.mobile {
					display: none;
				}
			}
		}

		&.delivered {
			padding-bottom: 40px;

			align-self: center;
			justify-self: center;

			.btn_continue {
				margin-top: 16px;
				display: none;
			}
		}
	}

	@media (width <= 767px) {
		.popup {
			width: 100vw;

			border-radius: 20px 20px 0px 0px;

			padding: 20px;

			h3 {
				@include header(mobile);
			}

			button[type='submit'] {
				@include button(mobile);
				margin: 16px 0 0;
			}

			&.success {
				.check {
					display: flex;
					gap: 8px;
					align-items: center;
					margin-bottom: 8px;
				}

				.img,
				h3 {
					margin-bottom: 0;
				}

				.text {
					font-size: 16px;
					line-height: 22px;
					color: $accent-color;
				}

				.btn_continue {
					min-height: 56px;
					border-radius: 20px;
					font-size: 16px;
					line-height: 22px;
				}
			}

			&.contact_us {
				width: 100%;
				min-width: auto;
				border-radius: 0;
				height: 100vh;
				padding-top: 68px;

				display: flex;
				flex-direction: column;

				overflow-y: auto;

				.wrapper {
					display: flex;
					flex-direction: column-reverse;
					gap: 40px;

					.account_form {
						padding: 0;

						height: 100%;
						display: flex;
						flex-direction: column;

						.input {
							> textarea {
								height: 80px;
							}

							.title {
								@include text(mobile);
							}

							&.message {
								.error {
									display: none;
								}

								&.with_error {
									.error {
										display: block;
									}
								}
							}
						}

						.captcha {
							margin-top: 16px;
							width: auto;
							height: 76px;
							margin-bottom: 16px;
						}

						button[type='submit'] {
							margin-top: auto;
						}
					}

					.info {
						max-width: 351px;

						display: flex;
						flex-direction: column;
						row-gap: 6px;
						column-gap: 19px;

						margin-top: 0;

						a {
							margin-bottom: 0;

							&.phone {
								grid-area: phone;
							}

							&.email {
								grid-area: email;
							}

							&.map {
								grid-area: map;
							}
						}

						&.without_phone {
							grid-template-areas:
								'email map'
								'email map';
						}

						&.without_phone_email {
							display: block;
						}
					}
				}
			}

			&.delivered {
				.btn_continue {
					margin-top: 24px;
				}
			}
		}
	}
}
