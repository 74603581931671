.app2 {
	.finish_container {
		box-sizing: border-box;
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		position: absolute;
		top: 0;
		left: 0;

		.finish {
			width: 197px;

			.img {
				width: 24px;
				height: 24px;
				background: url('#{$img-url}/success.svg') center center no-repeat;
				margin-bottom: 4px;
			}

			h3 {
				@include header(desktop);
				margin: 0px 0px 8px;
			}

			.text {
				@include text(desktop);
				margin: 0;
			}

			.btn_continue {
				@include button(desktop);
				padding: 0;
				margin: 24px 0 0;
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.finish_container {
			display: block;
			position: static;
			padding: 0 20px;
			margin: 20px auto 0;

			.finish {
				width: 100%;

				.check {
					display: flex;
					gap: 8px;
					align-items: center;
					margin-bottom: 8px;
				}

				.img {
					margin: 0;
				}

				h3 {
					@include header(mobile);
					margin: 0;
				}

				.text {
					@include text(mobile);
				}

				.btn_continue {
					@include button(mobile);
					width: auto;
					margin: 0;
					position: absolute;
					bottom: 20px;
					right: 20px;
					left: 20px;
				}
			}
		}
	}
}
