.dashboard {
	.dashboard_list.facility_details {
		form {
			label,
			input {
				font-size: 12px;
				line-height: 18px;
			}

			.input {
				margin-bottom: 13px;

				input {
					padding-left: 7px;
				}

				&.with_error {
					margin-bottom: 7px;
				}

				&:last-child {
					margin-bottom: 4px;
				}

				> .react-multi-email {
					@include textInput(desktop);
					height: auto;
					padding: 2px 0px 2px 2px;

					&.focused {
						border-color: $link-color;
					}

					&.empty {
						background: $light-gray-color;
					}

					div[data-tag] {
						color: $accent-color;
						background-color: $light-blue-color;
						font-weight: normal;
						display: flex;
						gap: 6px;
						margin: 2px;
						padding: 1px 6px;
						font-size: 12px;
						line-height: 18px;

						[data-tag-handle] {
							margin-left: 0px;
						}
					}

					input {
						font-size: 12px;
						font-family: Poppins, sans-serif;
						padding: 5px !important;
						margin: -2px 0px !important;
						color: $accent-color;
						background: transparent;
						line-height: 18px;
					}
				}
			}

			.actions {
				padding-top: 6px;
			}

			.checkbox {
				display: flex;
				margin-bottom: 10px;
				align-items: center;

				input {
					@include checkboxInput(desktop);
					border-color: $accent-color;

					&:checked {
						border-color: $accent-color;
						background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
					}
				}

				label {
					font-size: 14px;
					opacity: 100%;
					padding-bottom: 0;
					padding-left: 8px;
				}
			}

			.block {
				padding: 24px 0;
				border-top: 1px solid $border-color;

				&:first-child {
					border-top: none;
				}

				h2 {
					font-size: 18px;
					line-height: 27px;
					margin-bottom: 15px;
				}

				h3 {
					font-size: 14px;
					line-height: 21px;
					margin-bottom: 4px;
				}

				.description {
					font-size: 12px;
					line-height: 18px;
					opacity: 50%;
				}

				.checkboxes {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					margin-top: 14px;

					label {
						padding-left: 12px;
						font-size: 12px;
					}

					.checkbox {
						padding-right: 40px;
					}
				}

				&.basic {
					h2 {
						margin-bottom: 8px;
					}

					.input {
						padding-bottom: 29px;
						margin-bottom: 0;
					}

					.added_logo {
						display: flex;
						align-items: center;
						margin-top: 20px;

						.button {
							margin-top: 0;
						}

						img {
							width: 160px;
							height: 40px;
							object-fit: contain;
						}

						label {
							cursor: pointer;
							padding-bottom: 0;
						}

						.actions {
							margin-top: 0;
							padding-top: 0;
							padding-left: 16px;
						}
					}

					input {
						margin-bottom: 0;
					}

					label {
						opacity: 100%;
						font-size: 14px;
						line-height: 21px;
						width: 100%;
						display: block;
					}

					.button {
						margin-top: 12px;
					}
				}

				&.in_row {
					.input {
						justify-content: space-between;
						display: flex;
						flex-direction: row;
						opacity: 100%;
						margin-bottom: -4px;

						.description {
							margin-bottom: 0;
						}

						label {
							opacity: 100%;
						}

						input {
							font-size: 12px;
							line-height: 18px;

							padding: 0 5px;
							width: 40px;
							height: 40px;
							text-align: center;
						}
					}
				}

				&.schedule {
					dl {
						margin-top: 24px;
						margin-bottom: 0;
						font-size: 12px;
						line-height: 18px;

						display: grid;
						grid-template-columns: 106px auto;
						row-gap: 8px;
						column-gap: 40px;

						dt,
						dd {
							display: flex;
							align-items: center;

							&:first-of-type {
								opacity: 50%;
							}
						}

						dd {
							margin-inline-start: 0;
							width: 254px;
							justify-content: space-between;
						}

						span {
							opacity: 50%;
							font-size: 14px;
						}

						.list {
							top: 45px;
							margin-bottom: 0;
							padding: 0;
							display: block;
						}

						.selection {
							padding: 10px 7px;
							width: 106px;
							height: 40px;
							color: $accent-color;

							&::after {
								top: 16px;
								right: 11px;
							}
						}
					}
				}
			}
		}
	}
}
