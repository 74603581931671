.wide.calendar_section {
	padding: 0 40px;
	width: calc(100vw - 380px);
	overflow-x: auto;
	height: 100vh;
	overflow-y: scroll;

	.setup {
		background-color: $scrollbar-color;
	}

	.session {
		background-color: $session-color;
	}

	.pending {
		background-color: $pending-color;
	}

	.blocked {
		background-color: $border-color;
	}

	.header_block {
		display: flex;
		justify-content: space-between;
		padding-top: 40px;
		padding-bottom: 20px;
		margin-bottom: 8px;
		gap: 61px;

		border-bottom: 1px solid $border-color;

		h1 {
			font-size: 32px;
			font-family: Poppins;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		button {
			padding: 10px 24px;
			width: 180px;
			height: 40px;
		}

		.wrapper {
			flex-grow: 1;
			display: flex;
			grid-template-columns: repeat(2, 1fr);
			gap: 24px;

			.selection {
				display: flex;
				width: 120px;
				height: 40px;
				padding: 8px;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				color: $accent-color;

				&::after {
					top: 16px;
					right: 11px;
					background: url('#{$img-url}/arrow_down_blue_dashboard.svg') center no-repeat;
					height: 6px;
					width: 9px;
				}
			}

			.calendar_content {
				top: 48px;
			}

			.search {
				width: 100%;

				.search_fields {
					.calendar {
						&.mobile {
							display: none;
						}
					}

					.input {
						position: relative;

						> input {
							@include textInput(desktop);

							background: $light-gray-color url('#{$img-url}/search.svg') no-repeat left;
							background-position: 8px 10px;
							background-size: 18px;

							width: 300px;
							height: 40px;
							padding: 8px;

							padding-left: 34px !important;

							&:focus {
								border-color: $link-color;
							}

							&:not(:placeholder-shown) {
								background-color: white;

								+ .btn_close {
									display: block;
								}
							}

							&::placeholder {
								color: $gray-color;
							}
						}

						.btn_close {
							display: none;
							position: absolute;
							content: '';
							top: 6px;
							right: 8px;
							width: 18px;
							height: 18px;
							background: url('#{$img-url}/close.svg') center no-repeat;
						}
					}
				}
			}
		}
	}

	.timeline {
		display: flex;
		flex-direction: row;

		justify-content: flex-start;
		margin-bottom: 54px;

		div {
			min-width: calc($calendar-cell * 2);
			font-family: Poppins;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			text-align: center;
		}
	}

	.main {
		display: flex;
		gap: 12px;
		position: relative;

		h2 {
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 8px;
			white-space: nowrap;
		}

		h3 {
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			height: 24px;
			white-space: nowrap;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.names {
			width: max-content;
			overflow: visible;
			margin-top: 44px;
		}

		.schedules_box {
			position: relative;
			overflow: hidden;
			padding: 0 20px;

			.schedules {
				overflow-x: auto;
				width: 100%;
				margin-bottom: 20px;
			}

			.arrows {
				position: absolute;
				top: 0;
				width: 100%;
				left: 0px;
				display: flex;
				justify-content: space-between;

				a {
					width: 20px;
					height: 20px;
				}

				.arrow_left {
					left: 20px;
					background: url('#{$img-url}/arrow_left_stepper.svg') center no-repeat;
				}

				.arrow_right {
					right: 20px;
					background: url('#{$img-url}/arrow_left_stepper.svg') center no-repeat;
					transform: scaleX(-1);
				}
			}
		}
	}

	.block {
		margin-bottom: 20px;

		&.cells {
			margin-top: 24px;
			margin-bottom: 49px;

			&:last-child {
				margin-bottom: 20px;
			}
		}

		.table {
			display: flex;
			flex-direction: column;

			.schedule_box {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.schedule {
					display: flex;
					position: relative;

					.cell {
						box-sizing: border-box;
						width: $calendar-cell;
						height: 24px;
						border-top: 1px solid $accent-color;
						border-left: 1px solid $accent-color;

						&:last-child {
							border-right: 1px solid $accent-color !important;
						}

						&.first {
							border-left: 1px solid $accent-color !important;
						}

						transition: background 150ms ease-in-out;

						&.setup {
							background-color: $scrollbar-color;
							border-left: 0px;
						}

						&.session {
							background-color: $session-color;
							border-left: 0px;
						}

						&.pending {
							background-color: $pending-color;
							border-left: 0px;
						}

						&.blocked {
							background-color: $border-color;
							border-left: 0px;
						}

						&.hovered {
							&.setup {
								background-color: mix($scrollbar-color, $link-color, 75%);
							}

							&.session {
								background-color: mix($session-color, $link-color, 80%);
							}

							&.pending {
								background-color: mix($pending-color, $link-color, 80%);
							}

							&.blocked {
								background-color: mix($border-color, $link-color, 80%);
							}
						}

						&.selected {
							&.setup {
								background-color: mix($scrollbar-color, $link-color, 60%);
							}

							&.session {
								background-color: mix($session-color, $link-color, 60%);
							}

							&.pending {
								background-color: mix($pending-color, $link-color, 60%);
							}

							&.blocked {
								background-color: mix($border-color, $link-color, 60%);
							}
						}

						// &.setup {
						// 	border-left: 0px solid transparent;border-leftborder-leftborder-left
						// }
						//
						// &.session {
						// 	border-left: 0px solid transparent;border-leftborder-leftborder-left
						// }
						//
						// &.pending {
						// 	border-left: 0px solid transparent;border-leftborder-leftborder-left
						// }
						//
						// &.blocked {
						// 	border-left: 0px solid transparent;border-leftborder-leftborder-left
						// }
					}

					.label {
						position: absolute;
						pointer-events: none;
						background: transparent;
						padding: 0px 0px 0px 6px;
						font-size: 12px;
						line-height: 26px;
						box-sizing: border-box;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;

						color: $white;

						&.blocked {
							color: $accent-color;
						}
					}
				}

				&:last-child {
					.schedule .cell {
						border-bottom: 1px solid $accent-color;
					}
				}
			}
		}
	}

	.footer {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;

		ul {
			display: flex;
			align-items: center;
			gap: 39px;

			li {
				display: flex;
				gap: 8px;
				font-size: 12px;

				align-items: center;

				div {
					width: 20px;
					height: 20px;
					border: 1px solid $accent-color;
				}
			}
		}

		.exports {
			display: flex;
			gap: 23px;
			align-items: center;
			font-size: 12px;

			.actions {
				display: flex;
				gap: 16px;

				button {
					width: 99px;
					min-height: auto;
					height: 28px;
					padding: 0px 24px;
					border-radius: 4px;
					font-size: 12px;
				}
			}
		}
	}
}
