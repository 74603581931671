@import 'mixins.scss';
@import 'variables.scss';
@import 'https://fonts.googleapis.com/css2?family=Poppins&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0';
@import 'app2.scss';
@import 'dashboard.scss';
@import 'loader.scss';
@import 'sidebar.scss';
@import 'popup.scss';
@import 'stepper.scss';
@import 'dropdown.scss';
@import 'rooms.scss';
@import 'finish.scss';
@import 'calendar.scss';
@import 'date_time.scss';
@import 'details.scss';
@import 'search_calendar.scss';
@import 'requests.scss';
@import 'profile_info.scss';
@import 'notifications.scss';
@import 'facility_details.scss';
@import 'rooms_dashboard.scss';
@import 'request_form.scss';
@import 'feedback.scss';
@import 'analytics.scss';
@import 'accounts.scss';
@import 'calendar_dashboard.scss';
@import 'print.scss';
@import 'wysiwyg.scss';
@import 'asset_calendar.scss';
@import 'contact_us.scss';

@font-face {
	font-family: 'Microsoft Sans Serif';
	src: url('#{$fonts-url}/microsoft_sans_serif_regular.ttf');
	font-weight: normal;
	font-style: normal;
}
