.app2 {
	height: auto;

	.details {
		width: 436px;
		margin-top: 42px;
		margin-bottom: 32px;

		.input {
			.title {
				display: inline-block;

				@include text(desktop);
				font-size: 14px;
				line-height: 21px;
				margin-bottom: 4px;
			}

			.text {
				@include grayText(desktop);
				margin: 0 0 8px;
				padding: 0;
			}

			&.information {
				margin-bottom: 24px;
			}

			&.files {
				.text {
					margin-bottom: 16px;
				}

				.select_file {
					display: flex;
					align-items: center;

					input[type='file'] {
						width: 0.1px;
						height: 0.1px;
						opacity: 0;
						overflow: hidden;
						position: absolute;
						z-index: -1;
					}

					input[type='file'] + label {
						box-sizing: border-box;
						width: 108px;
						height: 38px;

						display: flex;
						justify-content: center;
						align-items: center;

						border: 1px solid $accent-color;
						border-radius: 8px;

						@include text(desktop);

						cursor: pointer;

						margin-right: 16px;
					}

					.limit {
						@include grayText(desktop);
					}
				}

				.selected_list {
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						@include text(desktop);
						height: 36px;
						padding: 9px 28px;
						box-sizing: border-box;
						border-bottom: 1px solid $border-color;

						position: relative;

						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;

						&:last-child {
							border-bottom: none;
						}

						&::before {
							position: absolute;
							content: '';
							top: 8px;
							left: 0px;
							width: 20px;
							height: 20px;
							background: url('#{$img-url}/file.svg') center no-repeat;
						}

						.btn_close {
							position: absolute;
							top: 13px;
							right: 5px;
							width: 10px;
							height: 10px;
							background: url('#{$img-url}/close.svg') center no-repeat;
							margin: -8px;
							padding: 8px;
						}
					}
				}
			}
		}

		button[type='submit'] {
			margin: 24px 0 0;
		}

		.message {
			@include text(desktop);
			text-align: center;
			margin: 10px 0 0;
		}

		.additional_info {
			margin-top: 24px;
			padding-top: 24px;
			border-top: 1px solid $border-color;

			.input {
				.form_radio {
					display: flex;
					align-items: center;
					gap: 7px;

					margin-bottom: 12px;
					margin-left: 2px;

					label {
						@include text(desktop);
						cursor: pointer;
					}

					input[type='radio'] {
						width: 16px;
						height: 16px;
						box-sizing: border-box;

						appearance: none;
						display: inline-block;

						padding: 2px;
						border: 1px solid $gray-color;
						border-radius: 50%;
						margin: 0;

						background: #fff;

						cursor: pointer;

						&:checked {
							background-color: $accent-color;
							border: 1px solid $accent-color;
							background-clip: content-box;
						}

						&:checked + label {
							color: $accent-color;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				input[name='custom_text'] {
					::placeholder {
						color: $gray-color;
					}
				}

				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				input[type='number'] {
					-moz-appearance: textfield;
				}

				&.checkbox {
					> label {
						margin-bottom: 0;
					}
				}
			}

			.note {
				padding-bottom: 16px;

				.text {
					@include text(desktop);
					border-left: 1px solid $accent-color;
					padding-left: 16px;

					a {
						text-decoration: none;
						color: #14a2e2;
					}
				}
			}

			hr {
				border: 0px;
				border-top: 1px solid $border-color;
				margin: 16px 0px;
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.details {
			margin: 48px 0 0;

			padding: 20px;
			width: 100%;

			.input {
				.title {
					@include text(mobile);
					margin-bottom: 8px;
				}

				.text {
					@include grayText(mobile);
					margin-bottom: 16px;
				}

				&.files {
					.text {
						margin-bottom: 12px;
					}

					.select_file {
						input[type='file'] + label {
							width: 115px;
							height: 44px;
							border-radius: 12px;

							@include text(mobile);
						}

						.limit {
							@include grayText(mobile);
						}
					}

					.selected_list {
						li {
							@include text(mobile);
							padding: 8px 28px;

							.btn_close {
								width: 12px;
								height: 12px;
								margin: -16px;
								padding: 16px;
								margin-right: -10px;
							}
						}
					}
				}
			}

			button[type='submit'] {
				margin-top: 32px;
			}

			.message {
				@include text(mobile);
			}

			.additional_info {
				.input {
					.form_radio {
						label {
							@include grayText(mobile);
						}
					}
				}

				.note {
					.text {
						@include text(mobile);
					}
				}
			}
		}
	}
}
