.dashboard {
	.dashboard_list.accounts_list {
		.header_block {
			justify-content: space-between;
			gap: 24px;
			margin-bottom: 16px;

			.actions {
				display: flex;
				gap: 16px;
				white-space: pre;

				.button {
					padding-left: 24px;
					padding-right: 24px;
				}
			}

			.tabs {
				width: 100%;
			}
		}

		.no_items {
			padding: 16px;
			opacity: 50%;
		}

		.list {
			li {
				gap: 8px;

				.user_photo {
					width: 40px;
					height: 40px;

					img,
					span {
						border-radius: 50%;
					}

					span {
						color: $white;
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 12px;
						line-height: 18px;
						background-color: $accent-color;
					}
				}

				.info {
					margin-right: auto;
					gap: 0;

					p {
						font-size: 14px;
						line-height: 21px;
					}

					span {
						font-size: 12px;
						line-height: 18px;
						opacity: 50%;
					}
				}

				.actions {
					gap: 8px;
				}
			}
		}
	}
}
