.dashboard {
	.dashboard_list.feedback_list {
		width: calc(100vw - 300px);
		display: flex;

		.main_list {
			width: 570px;
		}

		.rating {
			width: 76px;
			display: flex;
			gap: 4px;

			.circle {
				box-sizing: border-box;
				display: block;
				width: 12px;
				height: 12px;
				border: 1px solid $accent-color;
				border-radius: 10px;

				&.full {
					background-color: $accent-color;
				}
			}
		}

		.header_block {
			justify-content: space-between;
			margin-bottom: 8px;

			p {
				font-size: 18px;
				line-height: 27px;
			}

			.dropdown_box {
				span:not(.circle) {
					margin-right: 4px;
				}

				p,
				span {
					font-size: 12px;
					line-height: 18px;
					color: $accent-color;
				}

				.selection {
					width: 120px;
					height: 30px;
					display: flex;
					align-items: center;
					color: inherit;

					&::after {
						top: 12px;
						right: 11px;
						background: url('#{$img-url}/arrow_down_blue_dashboard.svg') center no-repeat;
						height: 6px;
						width: 9px;
					}
				}

				.open > .selection::after {
					background-image: url('#{$img-url}/arrow_down_blue_dashboard.svg') !important;
					top: 11px;
					transform: scale(-1);
				}

				.list {
					max-height: 180px;
					margin-top: 4px;
				}

				.list > .item {
					border-radius: 0;
					height: 30px;
					display: flex;
					padding-left: 7px;
					align-items: center;
					border-bottom: 1px solid $border-color;

					&:last-child {
						border: none;
					}
				}
			}
		}

		ul:not(.list) {
			gap: 11px;
			padding-bottom: 8px;
			margin-top: 22px;
			margin-bottom: 40px;
			border-bottom: 1px solid $border-color;
			display: flex;
			align-items: center;
			flex-direction: column;

			li {
				display: grid;
				grid-template-columns: min-content 1fr 35px;
				grid-column-gap: 12px;
				width: 100%;
				align-items: center;

				.rating {
					justify-content: flex-end;
				}

				p {
					font-size: 14px;
					line-height: 21px;
				}

				.bar {
					box-sizing: border-box;
					border: 1px solid $border-color;
					border-radius: 10px;
					height: 10px;

					div {
						box-sizing: border-box;
						border-radius: 10px;
						height: 10px;
						width: 100%;
						margin-top: -1px;
						background-color: $accent-color;
					}
				}
			}
		}

		.list {
			gap: 0;

			li {
				flex-direction: column;
				padding: 0;
				align-items: flex-start;
				margin-top: 16px;

				.info {
					flex-direction: row;
					max-width: 100%;
					width: 100%;
					justify-content: space-between;
					margin-bottom: 8px;
					align-items: center;

					h3 {
						font-weight: 600;
						font-size: 14px;
						line-height: 21px;
					}
				}

				p {
					font-size: 12px;
					line-height: 18px;
					margin-bottom: 8px;
				}

				> span {
					font-size: 12px;
					line-height: 18px;
					opacity: 50%;
					margin-bottom: 15px;
				}
			}
		}
	}

	.dashboard_list > .feedback_form,
	.feedback_form {
		width: calc(100vw - 1030px);
		padding: 40px;
		background-color: #fff;
		position: fixed;
		right: 0px;
		top: 0px;
		bottom: 0px;

		overflow: auto;

		.header_block {
			height: auto;
			margin-bottom: 24px;
			flex-direction: column;
			align-items: flex-start;

			h2 {
				padding-left: 0;
				margin-bottom: 4px;
			}

			p {
				font-size: 14px;
				line-height: 21px;
			}
		}

		div.box {
			border: 1px solid $border-color;
			border-radius: 6px;
			padding: 16px;
			display: inline-block;
		}

		h3 {
			margin-bottom: 16px;
			font-size: 18px;
			line-height: 27px;

			&.margin_top {
				margin-top: 24px;
				margin-bottom: 8px;
			}
		}

		.grade {
			display: flex;
			align-items: center;
			gap: 24px;
			font-size: 14px;
			line-height: 21px;
			margin-bottom: 24px;

			span {
				@include textInput(desktop);
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				font-size: inherit;
				line-height: inherit;
				padding: 6px 8px;

				&:focus,
				&:active {
					border-color: $border-color;
				}
			}
		}

		textarea {
			@include textInput(desktop);
			height: 84px;
			width: 474px;
			resize: none;

			&:focus,
			&:active {
				border-color: $border-color;
			}
		}

		.checkbox {
			display: flex;
			justify-content: start;
			align-items: center;
			margin-top: 32px;

			input {
				@include checkboxInput(desktop);
				border-color: $scrollbar-color;
				margin-right: 8px;

				&:checked {
					border-color: $accent-color;
					background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
				}
			}

			label {
				font-size: 12px;
				line-height: 18px;
			}
		}

		p {
			font-size: 12px;
			line-height: 18px;
			opacity: 50%;
			max-width: 500px;

			&.margin_bottom {
				margin-bottom: 16px;
			}
		}

		.checkbox {
			margin-top: 32px;
			display: flex;
			justify-content: start;
			align-items: center;

			margin-bottom: 10px;

			input {
				@include checkboxInput(desktop);
				border-color: $scrollbar-color;
				margin-right: 8px;

				&:checked {
					border-color: $accent-color;
					background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
				}
			}

			label {
				font-size: 14px;
				line-height: 18px;
				opacity: 1;
				padding-bottom: 0px;
			}
		}

		.rsw-editor {
			width: 506px;
		}

		form .actions {
			padding-top: 30px;
			width: 506px;

			button {
				transition: all 150ms ease-in-out;

				&[disabled] {
					opacity: 0.5;
					pointer-events: none;
				}
			}
		}
	}
}

.feedback_client {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	background-color: white;
	margin-top: 100px;

	.feedback_form {
		position: static;
		width: 506px;

		.header_block {
			margin-bottom: 0;
		}

		span {
			cursor: pointer;

			&.selected:not(:placeholder-shown) {
				background-color: $accent-color;
				color: white;
			}
		}

		> button {
			margin-top: 24px;
			width: 100%;
		}
	}
}
