.app2,
.dashboard {
	.calendar_content {
		width: 317px;
		max-height: 368px;
		box-sizing: border-box;

		border: 1px solid #14a2e2;

		background: #fff;
		border-radius: 8px;

		position: relative;

		.header {
			height: 50px;
			box-sizing: border-box;

			display: flex;
			align-items: center;
			justify-content: space-between;

			padding: 14px 14px 12px;

			.arrow {
				height: 20px;
				width: 20px;

				cursor: pointer;
				text-align: center;

				&.left {
					background: url('#{$img-url}/arrow_left_stepper.svg') center no-repeat;
				}

				&.right {
					background: url('#{$img-url}/arrow_right.svg') center no-repeat;
				}

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			.current_date {
				@include text(desktop);
				font-size: 12.68px;
				line-height: 19px;
			}
		}

		.body {
			padding: 0px 6px 10px;

			.weekdays,
			.days {
				display: grid;
				align-items: center;
				grid-template-columns: repeat(7, 1fr);

				margin: 0;
				padding: 0;
				list-style: none;

				li {
					@include text(desktop);
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.weekdays {
				background-color: $hover-dropdown-color;

				li {
					height: 44px;
				}
			}

			.days {
				li {
					height: 43px;

					cursor: pointer;
					position: relative;
					margin-top: 1px;
					font-weight: bold;

					&.event {
						&::before {
							content: '';
							position: absolute;
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background: $accent-color;
							bottom: 8px;
						}
					}

					&.inactive {
						color: $border-color;
						pointer-events: none;
						font-weight: normal;
					}

					&.active {
						color: #fff;
						background: $accent-color;
						border-radius: 8px;

						&::before {
							background: #fff;
						}
					}

					&.unavailable {
						color: #3b506c80;
						pointer-events: none;

						&::before {
							background: #3b506c80;
						}
						font-weight: normal;
					}

					&.demonstration {
						background: $light-blue-color;
						border-radius: 8px;
					}
				}
			}
		}

		.overlay {
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			right: 0;
			background-color: #ededed73;

			display: flex;
			align-items: center;
			justify-content: center;

			border-radius: 8px;
		}

		&.disabled {
			.body {
				pointer-events: none;
			}
		}
	}

	.dropdown.calendar {
		.calendar_content {
			position: absolute;
			top: calc(30px + 8px);

			transform: scaleY(0);
			transform-origin: top;
			transition: ease-in-out 0.3s;
		}

		&.open {
			.calendar_content {
				transform: scaleY(1);
				z-index: 300;
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.calendar_content {
			width: 100%;
			min-width: 320px;
			max-width: 420px;
			max-height: 410px;

			box-sizing: border-box;

			border-radius: 12px;

			.header {
				height: 58px;
				box-sizing: border-box;
				padding: 24px 20px 24px 16px;

				.arrow {
					height: 22px;
					width: 22px;
				}

				.current_date {
					@include text(mobile);
				}
			}

			.body {
				.weekdays {
					li {
						@include text(mobile);
						height: 48px;
					}
				}

				.days {
					li {
						@include text(mobile);
						height: 47px;
						margin-top: 2px;

						&.event {
							&::before {
								width: 5px;
								height: 5px;
							}
						}

						&.active {
							border-radius: 12px;
						}
					}
				}
			}
		}

		.dropdown.calendar {
			width: 100%;

			.calendar_content {
				top: calc(44px + 8px);
			}
		}
	}
}
