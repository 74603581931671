.app2 {
	.account_form {
		margin: 100px auto 0px;
		width: 278px;

		h3 {
			@include header(desktop);
			margin: 0px 0px 16px;
		}

		.input {
			position: relative;
			margin-bottom: 8px;

			@include inputError();

			> label {
				@include text(desktop);
				margin-bottom: 4px;
				cursor: pointer;
				display: block;

				> span {
					color: $error-color;
				}
			}

			> input {
				@include textInput(desktop);

				&:focus:not([type='checkbox']) {
					border-color: $link-color;
				}

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				&[type='number'] {
					-moz-appearance: textfield;
				}

				&[type='checkbox'] {
					@include checkboxInput(desktop);

					&:checked {
						border-color: $accent-color;
						background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
					}
				}
			}

			> .react-multi-email {
				@include textInput(desktop);
				height: auto;
				padding: 2px 0px 2px 2px;

				&.focused {
					border-color: $link-color;
				}

				&.empty {
					background: $light-gray-color;
				}

				div[data-tag] {
					color: $accent-color;
					background-color: $light-blue-color;
					font-weight: normal;
					display: flex;
					gap: 6px;
					margin: 2px;
					padding: 1px 6px;
					font-size: 12px;
					line-height: 18px;

					[data-tag-handle] {
						margin-left: 0px;
					}
				}

				input {
					font-size: 12px;
					font-family: Poppins, sans-serif;
					padding: 5px !important;
					margin: -2px 0px !important;
					color: $accent-color;
					background: transparent;
					line-height: 18px;
				}
			}

			> textarea,
			.rsw-ce {
				@include textInput(desktop);
				height: auto;
				min-height: 80px;
				overflow: auto;

				resize: none;

				&:focus {
					border-color: $link-color;
				}

				&::placeholder {
					color: $gray-color;
				}
			}

			.rsw-editor {
				.rsw-ce {
					display: block;
					border: 0px;
				}
			}

			> .toggler {
				width: 18px;
				height: 18px;
				display: block;

				background: url('#{$img-url}/icon_eye_cross.svg') center center no-repeat;

				cursor: pointer;

				position: absolute;
				right: 8px;
				top: 28px;
				text-decoration: none;

				&.toggled {
					background-image: url('#{$img-url}/icon_eye.svg');
				}
			}

			&.checkbox {
				display: flex;
				gap: 8px;

				> label {
					margin-bottom: 0px;
				}
			}

			.form_radio {
				display: flex;
				align-items: center;
				gap: 7px;

				margin-bottom: 12px;
				margin-left: 2px;

				label {
					@include grayText(desktop);
					cursor: pointer;
				}

				input[type='radio'] {
					width: 16px;
					height: 16px;
					box-sizing: border-box;

					appearance: none;
					display: inline-block;

					padding: 2px;
					border: 1px solid $gray-color;
					border-radius: 50%;
					margin: 0;

					background: #fff;

					cursor: pointer;

					&:checked {
						background-color: $accent-color;
						border: 1px solid $accent-color;
						background-clip: content-box;
					}

					&:checked + label {
						color: $accent-color;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.subform {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 24px;
			margin-top: 24px;

			> a {
				font-family: 'Microsoft Sans Serif';
				font-size: 12px;
				line-height: 14px;
				color: $link-color;
				text-decoration: none;
			}

			> .input {
				margin-bottom: 0px;

				> label {
					font-family: 'Microsoft Sans Serif';
				}
			}
		}

		button[type='submit'] {
			@include button(desktop);
			margin: 24px 0px;

			&:disabled {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		.account_options {
			font-family: 'Microsoft Sans Serif';
			text-align: center;
			font-size: 12px;
			line-height: 14px;
			margin: 0px;
			padding: 0px;
			color: $accent-color;

			a {
				color: $link-color;
				text-decoration: none;
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.account_form {
			margin: 20px auto 0;
			padding: 0 20px;
			width: 100%;
			box-sizing: border-box;

			h3 {
				@include header(mobile);
				margin: 0px 0px 20px;
			}

			.input {
				margin-bottom: 16px;

				> label {
					@include text(mobile);
				}

				> input {
					@include textInput(mobile);

					&[type='checkbox'] {
						@include checkboxInput(mobile);
					}
				}

				> textarea {
					@include textInput(mobile);
					height: 80px !important;
				}

				> .toggler {
					width: 24px;
					height: 24px;
					background-size: 24px;
					right: 10px;
					top: 36px;
				}

				> span.error {
					font-size: 16px;
					line-height: 18px;
					height: 18px;
				}

				> .react-multi-email {
					> input {
						@include textInput(mobile);
					}
				}
			}

			.subform {
				> a {
					font-size: 16px;
					line-height: 18px;
				}
			}

			button[type='submit'] {
				@include button(mobile);
			}

			.account_options {
				font-size: 16px;
				line-height: 18px;
				margin-bottom: 48px;
			}

			&.create {
				.input {
					margin-bottom: 6px;
				}
			}
		}
	}
}
