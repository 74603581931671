@use 'sass:map';

.dashboard {
	.dashboard_list.analytics {
		width: 100%;
		display: block;

		.header_block {
			width: 100%;
			justify-content: space-between;
			margin-bottom: 24px;

			.time_period {
				display: flex;
				gap: 28px;

				font-size: 14px;
				line-height: 21px;

				position: relative;

				> div {
					display: flex;
					align-items: center;
					gap: 16px;

					.dropdown {
						display: flex;
						gap: 16px;

						.selection {
							padding: 10px 7px;
							width: 160px;
							color: $accent-color;
							height: 40px;

							&::after {
								top: 16px;
								right: 11px;
								background: url('#{$img-url}/arrow_down_blue_dashboard.svg') center no-repeat;
							}
						}

						.open > .selection::after {
							background-image: url('#{$img-url}/arrow_down_blue_dashboard.svg') !important;
							top: 15px;
							transform: scale(-1);
						}

						.calendar_content {
							top: 48px;
						}

						&.right {
							.calendar_content {
								right: 0px;
							}
						}
					}
				}
			}
		}

		> p {
			margin-bottom: 24px;
		}
	}

	.main_block {
		display: grid;
		grid-template-columns: 692px 385px;
		grid-column-gap: 40px;

		.in_row {
			display: grid;
			grid-template-columns: 388px 260px;
			grid-column-gap: 40px;
		}

		.block {
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}

			h2 {
				font-weight: 500;
				font-size: 18px;
				line-height: 27px;
				margin-bottom: 16px;
			}

			ul,
			& > div {
				background-color: $white;
				padding: 24px;
				border-radius: 8px;
			}

			ul:not(.list) {
				gap: 40px;
				display: flex;

				li {
					display: flex;
					flex-direction: column;
					gap: 4px;

					&.main {
						border-right: 1px solid $border-color;
						padding-right: 40px;
						width: 106px;
						gap: 2px;
					}

					h3 {
						font-size: 32px;
						line-height: 48px;
					}

					p,
					span {
						font-size: 18px;
						line-height: 27px;
					}

					span {
						opacity: 50%;
					}
				}
			}

			&.diagram_box {
				> div {
					padding-bottom: 0;
					display: flex;
				}

				.diagram {
					width: 180px;
					height: 180px;
					margin-right: 40px;
				}

				ul {
					height: 204px;
					flex-direction: column;
					flex-wrap: wrap;
					gap: 24px 40px;
					padding: 0;

					li {
						flex-direction: row;
						align-items: center;
						font-size: 18px;
						line-height: 27px;

						p {
							min-width: 36px;
						}

						span {
							margin-right: 12px;
							opacity: 100%;
							width: 20px;
							height: 20px;
							border-radius: 4px;
						}

						@for $i from 1 through 7 {
							&:nth-child(#{$i}) > span {
								background-color: map.get($diagram-colors, $i);
							}
						}
					}
				}
			}

			.list > li,
			.list_head {
				display: grid;
				grid-template-columns: 360px 82.5px 82.5px;
				grid-column-gap: 40px;
				justify-content: start;
			}

			.list_head {
				font-size: 18px;
				line-height: 27px;
				opacity: 50%;
				padding-left: 16px;
				padding-bottom: 16px;
			}

			.list {
				padding: 0;
				gap: 0;
				margin-bottom: 0;

				li {
					padding-top: 16px;
					padding-bottom: 15.2px;
					font-size: 18px;
					line-height: 27px;
				}
			}
		}

		.right_side {
			.block {
				margin-bottom: 43px;

				& > div {
					padding: 24px;
					padding-top: 26.5px;
				}

				.list_head {
					grid-template-columns: 148.5px 148.5px;

					font-size: 18px;
					line-height: 27px;
					opacity: 50%;
					padding-left: 0;
				}

				.list {
					padding: 0;
					gap: 8px;
					padding-top: 0;

					li {
						grid-template-columns: 148.5px 23px 35px;

						padding: 0;
						border: none;
					}
				}

				h3 {
					font-size: 32px;
					line-height: 48px;
				}
			}
		}
	}
}
