.app2 {
	.stepper_wrap {
		width: 674px;
		margin: 40px auto 0;
		padding: 0;
		position: relative;

		&.disabled {
			pointer-events: none;
		}
	}

	.arrow_left {
		display: block;
		cursor: pointer;
		position: absolute;
		content: '';
		width: 20px;
		height: 20px;
		background: $hover-dropdown-color url('#{$img-url}/arrow_left_stepper.svg') center center no-repeat;
		border-radius: 50%;
		left: -28px;
		bottom: -4px;
		text-decoration: none;
	}

	.stepper {
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		li {
			list-style: none;

			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: 0px;

			display: flex;
			flex-direction: column;
			align-items: center;

			cursor: pointer;

			p {
				@include grayText(desktop);
				margin: 0 0 6px;
			}

			.step {
				box-sizing: border-box;
				width: 12px;
				height: 12px;

				border-radius: 50%;

				display: grid;
				place-items: center;

				position: relative;
				background-color: white;

				&::after {
					box-sizing: border-box;
					width: 6px;
					height: 6px;
					border-radius: 50%;

					position: absolute;
					content: '';

					background-color: $border-color;
				}

				&::before {
					width: 322px;
					height: 2px;

					content: '';
					position: absolute;
					right: 12px;

					transition: all ease-in-out 0.6s;
					z-index: -1;
					background-color: $border-color;
				}
			}

			&:first-child {
				align-items: flex-start;

				.step {
					&::before {
						display: none;
					}
				}
			}

			&:last-child {
				align-items: flex-end;
			}

			&.active,
			&.done {
				.step {
					&::after {
						width: 12px;
						height: 12px;
						border: 2px solid $accent-color;
						background-color: white;
					}

					&::before {
						background-color: $accent-color;
					}
				}
			}

			&.done {
				cursor: pointer;

				.step {
					&::after {
						border: none;
						background: $accent-color url('#{$img-url}/check_mark.svg') center center no-repeat;
					}
				}
			}

			&.disabled {
				cursor: auto;
				pointer-events: none;
			}
		}
	}
}

@media (width <= 767px) {
	.app2 {
		.stepper_wrap {
			display: none;
		}
	}
}
