.dashboard {
	.dashboard_list.rooms_list {
		.header_block {
			justify-content: space-between;
			margin-bottom: 24px;
		}

		&.assets_list {
			.header_block:nth-child(4) {
				margin-top: 40px;
			}
		}

		.list > li,
		.list_head {
			display: grid;
			grid-template-columns: 2fr min-content 1fr;
			grid-column-gap: 40px;
			justify-content: start;
		}

		.list_head {
			font-size: 14px;
			opacity: 50%;
			padding-left: 16px;
			padding-bottom: 16px;
		}

		.list {
			gap: 0;

			li {
				min-height: 56px;
				padding: 16px;
				padding-top: 15px;
				font-size: 14px;
				line-height: 21px;

				&.active {
					padding-top: 16px;
				}

				&[draggable='true'] {
					padding-left: 32px;
					background: url('#{$img-url}/move.svg') 12px center no-repeat;
					cursor: grab;

					&.dragging {
						cursor: grabbing;
					}
				}
			}

			.actions {
				margin-left: auto;
				gap: 8px;
			}

			&.staff {
				> li {
					grid-template-columns: 2fr min-content;
				}
			}
		}
	}

	.dashboard_right_section > .room_item {
		.header_block {
			margin-bottom: 0;
		}

		form {
			.actions {
				padding-top: 14px;

				&.create > button {
					flex-basis: 100%;
				}
			}

			.block {
				padding: 40px 0 39px;
				display: flex;
				flex-direction: column;
				gap: 40px;
				border-top: 1px solid $border-color;

				&:first-child {
					border: none;
				}

				label,
				h3 {
					font-size: 18px;
					line-height: 27px;
					opacity: 100%;
				}

				.description {
					font-size: 12px;
					line-height: 18px;
					opacity: 50%;
				}

				textarea {
					height: 84px;
					margin-top: 12px;
				}

				.add_item {
					h3 {
						margin-bottom: 7px;
					}

					a {
						display: block;
						font-size: 14px;
						line-height: 21px;
						color: $link-color;
						text-decoration: none;
					}

					.input {
						flex-direction: row;
						margin-bottom: 16px;

						label {
							display: flex;
							align-items: center;
							font-size: 14px;
							padding: 0;
							line-height: 21px;
							margin-right: 40px;
						}

						input {
							width: 200px;
						}

						.icon {
							width: 30px;
							height: 40px;
							margin-left: 4px;
						}
					}
				}

				.photo {
					display: grid;
					grid-template-areas:
						'p p'
						'img btn';

					grid-template-columns: min-content 1fr;

					p {
						font-size: 14px;
						line-height: 21px;
						grid-area: p;
						margin-bottom: 16px;
					}

					.input {
						grid-area: img;
						cursor: pointer;

						.image {
							width: 100px;
							height: 100px;
							margin-right: 16px;
							font-size: 36px;
							color: $white;
							display: flex;
							justify-content: center;
							align-items: center;
							position: relative;

							img {
								object-position: center;
								object-fit: cover;
							}

							.action {
								position: absolute;
								display: none;
							}

							&:hover {
								.action {
									display: flex;
									justify-content: center;
									align-items: center;
									width: 100%;
									height: 100%;
								}
							}
						}
					}

					&.with_photo {
						.button {
							display: none;
						}

						.action:hover {
							background: linear-gradient(0deg, rgb(59 80 108 / 0.5), rgb(59 80 108 / 0.5));
						}

						.icon {
							grid-area: btn;
						}
					}

					&.without_photo {
						.image {
							background-image: url('#{$img-url}/no_room.png');

							&:hover {
								background: linear-gradient(0deg, rgb(59 80 108 / 0.5), rgb(59 80 108 / 0.5)), url('#{$img-url}/no_room.png');
							}
						}

						.button {
							grid-area: btn;
							height: 46px;
						}

						.icon {
							display: none;
						}
					}
				}

				&.in_row {
					.input {
						display: flex;
						flex-direction: row;
						align-items: center;
						opacity: 100%;
						width: 400px;
						box-sizing: border-box;

						.description {
							margin-bottom: 0;
						}

						label {
							padding-bottom: 0;
							padding-right: 17px;
						}

						input {
							font-size: 12px;
							line-height: 18px;
							text-align: center;

							padding: 14px 11px;
							width: 65px;
							height: 40px;
						}
					}
				}

				&.schedule {
					gap: 0;

					h3 {
						margin-bottom: 8px;
					}

					p {
						font-size: 12px;
						line-height: 18px;
						opacity: 50%;
						margin-bottom: 16px;
					}

					ul {
						display: flex;
						flex-direction: column;
						gap: 16px;

						li {
							display: grid;
							grid-template-columns: 60px 120px 106px;
							grid-column-gap: 16px;

							p {
								font-size: 12px;
								line-height: 18px;
								display: flex;
								align-items: center;
								margin-bottom: 0;
							}

							.selection {
								padding: 10px 7px;
								height: 40px;
								width: 200px;
								color: $accent-color;

								&::after {
									top: 16px;
									right: 11px;
								}
							}

							.list {
								width: 200px;
								top: 45px;
								margin-bottom: 0;
								padding: 0;
								display: block;
							}
						}
					}
				}
			}
		}
	}
}
