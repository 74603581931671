@import 'variables.scss';

.sidebar {
	font-family: Poppins;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	box-sizing: border-box;
	width: 300px;
	padding: 40px;
	height: 100vh;
	background-color: #fff;

	.user {
		display: flex;
		align-items: center;

		height: 40px;

		.photo {
			width: 40px;
			height: 40px;
			margin-right: 10px;
			border-radius: 50%;
		}

		span {
			@extend .photo;
		}

		> .registered {
			background: rgb(59 80 108);
			color: white;

			&::before {
				display: flex;
				text-align: center;
				justify-content: center;
				line-height: 40px;

				content: attr(data-name);
			}
		}

		.expand_menu {
			width: 24px;
			height: 24px;
			margin-left: 8px;

			cursor: pointer;

			background: url('#{$img-url}/arrow_down_blue.svg') center center no-repeat;

			&.selected {
				background: url('#{$img-url}/arrow_up_blue.svg') center center no-repeat;
			}
		}

		.info {
			.name {
				font-size: 14px;
				line-height: 21px;

				/* Dark Blue */
				color: #3b506c;
			}

			.email {
				font-size: 10px;
				line-height: 15px;

				/* Dark Blue 50% */
				color: rgb(59 80 108 / 0.5);
			}
		}
	}

	.menu {
		margin-top: 16px;
		margin-right: -20px;

		&.expandable {
			display: none;

			margin: 4px -20px 4px 4px;

			&.expanded {
				display: block;
			}
		}

		.title {
			padding: 8px;

			font-family: Poppins;
			font-size: 10px;
			line-height: 15px;
			text-transform: uppercase;

			/* Dark Blue */
			color: #3b506c;
		}

		.menu_item {
			display: flex;
			text-decoration: none;

			padding-left: 0px;
			height: 37px;

			align-items: center;

			font-size: 14px;
			line-height: 21px;

			margin-bottom: 1px;

			/* Dark Blue */
			color: #3b506c;

			border-radius: 8px;

			&:hover {
				background-color: rgba($background-accent, 0.5);
			}

			&.selected {
				font-weight: 600;
				background-color: $background-accent;
			}

			.icon {
				display: flex;
				flex-direction: row;
				width: 20px;
				height: 20px;
				margin-left: 8px;
				margin-right: 6px;

				background-position: center;
				background-repeat: no-repeat;
			}

			&.profile_settings .icon {
				background-image: url('#{$img-url}/profile-settings.svg');
			}

			&.logout {
				color: #eb6282;

				.icon {
					background-image: url('#{$img-url}/logout.svg');
				}
			}

			&.requests {
				.icon {
					background-image: url('#{$img-url}/request-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/request-active.svg');
				}
			}

			&.notifications {
				.icon {
					background-image: url('#{$img-url}/notification-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/notification-active.svg');
				}
			}

			&.details {
				.icon {
					background-image: url('#{$img-url}/details-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/details-active.svg');
				}
			}

			&.rooms {
				.icon {
					background-image: url('#{$img-url}/room-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/room-active.svg');
				}
			}

			&.assets {
				.icon {
					background-image: url('#{$img-url}/icon_assets.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/icon_assets-active.svg');
				}
			}

			&.request_form {
				.icon {
					background-image: url('#{$img-url}/request-form-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/request-form-active.svg');
				}
			}

			&.feedback {
				.icon {
					background-image: url('#{$img-url}/feedback-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/feedback-active.svg');
				}
			}

			&.calendar {
				.icon {
					background-image: url('#{$img-url}/calendar-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/calendar-active.svg');
				}
			}

			&.analytics {
				.icon {
					background-image: url('#{$img-url}/analytics-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/analytics-active.svg');
				}
			}

			&.accounts {
				.icon {
					background-image: url('#{$img-url}/accounts-default.svg');
				}

				&.selected .icon {
					background-image: url('#{$img-url}/accounts-active.svg');
				}
			}

			.counter {
				display: flex;
				justify-content: center;

				width: 18px;
				height: 18px;
				margin-left: 6px;

				font-size: 10px;
				line-height: 10px;
				align-items: center;
				text-align: center;

				font-weight: 400 !important;

				background-color: #db6b83;
				color: white;
				border-radius: 10px;
			}
		}
	}

	.border_button {
		display: flex;
		justify-content: center;
		align-items: center;

		background-color: white;
		color: #3b506c;
		border: 1px solid #3b506c;
		border-radius: 8px;

		font-family: Poppins;
		font-size: 14px;
		line-height: 21px;

		margin-top: 24px;
		width: 100%;
		box-sizing: border-box;
		height: 40px;

		text-decoration: none;
		text-align: center;
	}

	.logo {
		height: 32px;
		object-fit: contain;
	}
}
