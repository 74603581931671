.popup_container {
	display: flex;
	opacity: 0;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgb(0 0 0 / 0.2);
	transition: opacity ease-in-out 0.3s;
	pointer-events: none;
	z-index: 999999;

	.popup {
		width: 358px;
		box-sizing: border-box;
		background-color: #fff;

		border-radius: 8px;

		padding: 40px;

		position: relative;

		&.scrollable {
			max-height: calc(100vh - 60px);
			overflow-y: auto;
			overflow-x: hidden;
		}

		.btn_close {
			width: 20px;
			height: 20px;

			background: url('#{$img-url}/close.svg') center no-repeat;

			position: absolute;
			top: 8px;
			right: 8px;

			cursor: pointer;

			border: none;
		}

		h3 {
			@include header(desktop);
			margin: 0px 0px 16px;
		}

		p {
			font-weight: 400;
			font-size: 12px;
			line-height: 18px;
			margin-bottom: 16px;
		}

		button[type='submit'] {
			margin: 22px 0 0;
		}

		&.custom_input_add {
			width: auto;

			.btn_close {
				background-image: url('#{$img-url}/close_big.svg');
				width: 30px;
				height: 30px;
				flex-shrink: 0;
			}

			h2 {
				font-family: Poppins;
				font-size: 32px;
				line-height: 48px;
				font-weight: 400;
				margin-top: 0;
				margin-bottom: 8px;
				color: $accent-color;
			}

			h3 {
				margin-bottom: 24px;
			}

			.types {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-column-gap: 30px;
				grid-row-gap: 24px;
				margin-bottom: 32px;

				div {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 8px;
					overflow: visible;
					cursor: pointer;

					p {
						font-size: 14px;
						font-family: Poppins;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						color: $accent-color;
						margin: 0;
						white-space: nowrap;
					}

					span {
						display: grid;
						width: 40px;
						height: 40px;

						&.text {
							background: url('#{$img-url}/input_text.svg') center no-repeat;
						}

						&.long_text {
							background: url('#{$img-url}/input_text_long.svg') center no-repeat;
						}

						&.note {
							background: url('#{$img-url}/input_note.svg') center no-repeat;
						}

						&.checkbox {
							background: url('#{$img-url}/input_checkbox.svg') center no-repeat;
						}

						&.radio {
							background: url('#{$img-url}/input_radio.svg') center no-repeat;
						}

						&.number {
							position: relative;

							&::before {
								content: '123';
								font-size: 14px;
								font-family: Poppins;
								position: absolute;
								border: 2.5px solid $accent-color;
								width: 40px;
								text-align: center;
								color: $accent-color;

								top: 5px;
								left: -3px;
							}
						}

						&.divider {
							position: relative;

							&::before {
								content: '';
								position: absolute;
								border: 2.5px solid $accent-color;
								width: 40px;
								text-align: center;
								color: $accent-color;

								top: 50%;
								left: -3px;
							}
						}
					}
				}
			}
		}

		&.success {
			padding-bottom: 31px;

			.img {
				width: 24px;
				height: 24px;
				background: url('#{$img-url}/success.svg') center center no-repeat;
				margin-bottom: 4px;
			}

			h3 {
				margin-bottom: 8px;
			}

			.text {
				@include grayText(desktop);
				margin: 0;
			}

			.btn_continue {
				@include button(desktop);
				padding: 0;
				margin: 24px 0 0;

				&:disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}
		}

		&.room {
			width: 436px;

			h3 {
				margin: 0 0 16px 0px;
			}

			.content {
				display: flex;
				flex-direction: column;
				gap: 16px;

				img {
					display: block;
					width: 100%;
					height: auto;
					object-fit: cover;
				}

				.text {
					@include grayText(desktop);
					margin: 0;
				}

				.setup,
				.cleanup,
				.duration {
					@include text(desktop);
				}
			}

			.btn_back {
				display: none;
			}
		}

		&.sign_in {
			width: 436px;

			.account_form {
				width: 100%;

				.input {
					margin-bottom: 7px;

					&.checkbox {
						margin-bottom: 0px;
					}
				}

				.subform {
					margin: 0;
				}

				button[type='submit'] {
					margin: 24px 0 16px;
				}
			}

			.separator {
				@include text(desktop);
				line-height: 14px;
				margin: 24px 0;
				text-align: center;
			}

			.btn_continue_guest {
				@include button(desktop);
				background-color: $light-blue-color;
				color: $accent-color;
				padding: 0;
			}
		}

		&.blocked_time {
			width: 635px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;

			h3 {
				margin: 0;
			}

			.btn_close {
				background-image: url('#{$img-url}/close_big.svg');
				width: 30px;
				height: 30px;
				flex-shrink: 0;
			}

			.checkbox {
				margin-bottom: 8px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;

				label {
					font-family: Poppins;
					padding-left: 12px;
					cursor: pointer;
					color: $accent-color;
				}

				input[type='checkbox'] {
					@include checkboxInput(desktop);
					border-color: $accent-color;

					&:checked {
						background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
					}

					&:disabled {
						border-color: $gray-color;
					}
				}

				&.disabled {
					pointer-events: none;

					label {
						color: $gray-color;
					}
				}
			}

			p {
				font-family: Poppins;
				opacity: 100%;
				font-size: 14px;
				margin: 0;
				color: $accent-color;
				font-style: normal;
				font-weight: 400;
			}

			button {
				@include button(desktop);
				padding: 0;

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}

				&[disabled] {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			.dates {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.date,
				.range {
					display: flex;
					align-items: center;
					gap: 16px;

					.selection {
						display: flex;
						height: 40px;
						padding: 8px;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						color: $accent-color;

						&::after {
							top: 16px;
							right: 11px;
							background: url('#{$img-url}/arrow_down_blue_dashboard.svg') center no-repeat;
							height: 6px;
							width: 9px;
						}
					}

					.calendar_content {
						top: 48px;
					}
				}

				.date .selection {
					width: 140px;
				}

				.range {
					.list {
						top: 48px;
						margin-bottom: 0;
						padding: 0;
						display: block;
					}

					.selection {
						width: 105px;
					}
				}

				&.intervals {
					flex-direction: column;
					width: 100%;
					gap: 16px;

					> div {
						display: flex;
						align-items: center;
						gap: 16px;
						justify-content: space-between;
					}

					.selection {
						width: 200px;
					}
				}
			}

			.input {
				width: 100%;

				input {
					@include textInput(desktop);
					height: 40px;
				}
			}

			.rooms_wrapper {
				display: flex;
				gap: 8px;
				flex-direction: column;
				width: 100%;
			}

			.rooms {
				.all_rooms {
					font-weight: 600;
				}

				.columns {
					width: 100%;
					display: grid;
					grid-template-columns: repeat(3, minmax(0, 1fr));
					column-gap: 24px;
					max-height: 380px;
					overflow-y: auto;
				}
			}

			button {
				margin: 0;
			}
		}

		&.calendar_export {
			width: 475px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;

			h3 {
				margin: 0;
			}

			.btn_close {
				background-image: url('#{$img-url}/close_big.svg');
				width: 30px;
				height: 30px;
				flex-shrink: 0;
			}

			p {
				font-family: Poppins;
				opacity: 100%;
				font-size: 14px;
				margin: 0;
				color: $accent-color;
				font-style: normal;
				font-weight: 400;
			}

			button {
				@include button(desktop);
				padding: 0;

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			.dates {
				width: 100%;
				display: flex;
				justify-content: space-between;

				.date {
					display: flex;
					align-items: center;
					gap: 16px;

					.selection {
						display: flex;
						height: 40px;
						padding: 8px;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						color: $accent-color;

						&::after {
							top: 16px;
							right: 11px;
							background: url('#{$img-url}/arrow_down_blue_dashboard.svg') center no-repeat;
							height: 6px;
							width: 9px;
						}
					}

					.calendar_content {
						top: 48px;
					}
				}

				.date .selection {
					width: 140px;
				}
			}

			.checkbox {
				margin-bottom: 8px;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				display: flex;
				align-items: center;

				label {
					font-family: Poppins;
					padding-left: 12px;
					cursor: pointer;
					color: $accent-color;
				}

				input[type='checkbox'] {
					@include checkboxInput(desktop);
					border-color: $accent-color;

					&:checked {
						background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
					}

					&:disabled {
						border-color: $gray-color;
					}
				}

				&.disabled {
					pointer-events: none;

					label {
						color: $gray-color;
					}
				}
			}

			.fields_wrapper {
				display: flex;
				gap: 8px;
				flex-direction: column;
				width: 100%;
			}

			.fields {
				.columns {
					width: 100%;
					column-gap: 24px;
					max-height: 500px;
					overflow-y: auto;
				}
			}

			button {
				margin: 0;
			}
		}

		&.edit_setup {
			width: 397px;

			h3 {
				@include header(desktop);
				margin-bottom: 24px;
			}

			h4,
			.text {
				@include text(desktop);
				color: $error-color;
				margin: 0;
			}

			h4 {
				font-weight: 500;
			}

			.row {
				display: flex;
				gap: 16px;
				align-items: center;

				margin-top: 16px;

				.time {
					@include text(desktop);
					flex: 0 0 85px;
				}

				.dropdown {
					width: 100%;
				}
			}

			.btn_save {
				@include button(desktop);
				padding: 0;
				margin-top: 24px;

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}
			}
		}

		&.contact_us {
			width: auto;
			min-width: 635px;

			.btn_close {
				width: 30px;
				height: 30px;
				background-size: 30px;
			}

			.wrapper {
				display: grid;
				grid-template-columns: min-content 1fr;
				gap: 42px;

				.account_form {
					min-width: 304px;

					.input {
						margin-bottom: 16px;

						> textarea {
							height: 60px;
						}

						.title {
							@include text(desktop);
							margin-bottom: 4px;
						}

						&.message {
							margin-bottom: 0;
						}
					}

					button[type='submit'] {
						margin: 24px 0 0;
					}
				}

				.info {
					margin-top: 22px;
					min-width: 196px;

					a {
						@include grayText(desktop);
						text-decoration: none;

						display: block;
						position: relative;

						margin-bottom: 18px;
						padding-left: 28px;

						&::before {
							content: '';
							width: 20px;
							height: 20px;
							position: absolute;
							top: 0;
							left: 0;
						}

						&.phone::before {
							background: url('#{$img-url}/phone.svg') center center no-repeat;
						}

						&.email::before {
							background: url('#{$img-url}/email.svg') center center no-repeat;
						}

						&.map {
							display: flex;
							flex-direction: column;

							div {
								display: inline;
								width: min-content;
								min-width: 100%;
							}

							&::before {
								background: url('#{$img-url}/map.svg') center center no-repeat;
							}
						}
					}
				}
			}

			button[type='submit'] {
				&.mobile {
					display: none;
				}
			}
		}

		.booking {
			h3 {
				margin-bottom: 8px;
			}

			p {
				margin-top: 0;
			}

			textarea {
				margin-bottom: 12px;
			}
		}

		.actions {
			display: flex;
			justify-content: space-between;
			gap: 16px;

			height: 40px;
			width: 100%;

			button {
				flex-basis: 50%;
			}

			button {
				margin-top: 0;

				@include button(desktop);
				border: 1px solid $accent-color;
				color: $accent-color;
				background-color: transparent;

				&[type='submit'] {
					background-color: $accent-color;
					color: $white;
				}

				&:disabled {
					pointer-events: none;
					opacity: 0.5;
				}
			}
		}

		&.added_user {
			width: 400px;
		}

		&.user_success {
			width: 277px;

			.title {
				display: flex;
				gap: 4px;

				span {
					display: block;
					background: url('#{$img-url}/success.svg') center no-repeat;
					width: 24px;
					height: 24px;
				}
			}

			button[type='submit'] {
				margin: 0;
			}
		}

		.user_action {
			width: auto;

			h3 {
				margin-bottom: 8px;
			}

			p {
				opacity: 100%;
				margin-top: 0;
				font-family: Poppins;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				color: $scrollbar-color;
			}

			span {
				color: $accent-color;
			}

			.content {
				margin-top: 16px;
				margin-bottom: 24px;

				display: flex;
				flex-direction: column;
				gap: 8px;

				.input {
					margin-bottom: 0;

					input {
						height: 40px;
						padding: 6px 8px;
					}
				}

				.notation {
					opacity: 100%;
					padding: 8px 16px;
					border-radius: 8px;
					background: $background-accent;
					margin-bottom: 8px;
					color: $accent-color;

					a {
						color: $link-color;
						cursor: pointer;
					}
				}
			}
		}

		.saved_changes {
			div {
				display: flex;
				gap: 4px;
			}

			h3 {
				margin-bottom: 8px;
			}

			span {
				display: block;
				background: url('#{$img-url}/success.svg') center no-repeat;
				width: 24px;
				height: 24px;
			}

			p {
				margin-top: 0;
				margin-bottom: 16px;
			}

			button {
				width: 269px;
				height: 40px;
				font-size: 14px;
				margin: 0;
			}

			.column {
				flex-direction: column;
			}
		}

		&.delivered {
			padding-bottom: 40px;

			.btn_continue {
				margin-top: 16px;
			}
		}

		.schedule_error {
			font-family: Poppins;
			color: $error-color;
			font-size: 16px;
			line-height: 18px;
			height: 18px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 4px;
			width: 100%;
			margin-top: 20px;
		}
	}

	.account_form {
		margin: 0px;

		.note .text {
			a {
				color: #14a2e2;
				text-decoration: none;
			}
		}

		.actions {
			margin-top: 32px;
		}
	}

	&.open {
		pointer-events: all;
		opacity: 1;
	}
}

@media (width <= 767px) {
	.popup_container {
		justify-content: flex-end;
		align-items: flex-end;

		.popup {
			min-width: auto;
			width: 100vw;

			border-radius: 20px 20px 0px 0px;

			padding: 20px;

			.btn_close {
				top: 10px;
				right: 10px;
			}

			h3 {
				@include header(mobile);
			}

			button[type='submit'] {
				@include button(mobile);
				margin: 16px 0 0;
			}

			&.success {
				.check {
					display: flex;
					gap: 8px;
					align-items: center;
					margin-bottom: 8px;
				}

				.img,
				h3 {
					margin-bottom: 0;
				}

				.text {
					font-size: 16px;
					line-height: 22px;
					color: $accent-color;
				}

				.btn_continue {
					min-height: 56px;
					border-radius: 20px;
					font-size: 16px;
					line-height: 22px;
				}
			}

			&.room {
				width: 100vw;
				max-height: 100vh;
				overflow-y: auto;

				h3 {
					margin-bottom: 8px;
				}

				.content {
					.duration {
						@include text(mobile);
					}

					.text {
						@include grayText(mobile);
					}

					.setup,
					.cleanup {
						@include text(mobile);
					}
				}

				.btn_back {
					@include button(onlyMobile);
					padding: 0px;
					margin: 50px 0 0;
				}
			}

			&.sign_in {
				width: 100vw;

				.account_form {
					.input {
						margin-bottom: 14px;
					}

					.subform {
						margin-top: 25px;
					}

					button[type='submit'] {
						margin: 24px 0;
					}
				}

				.separator {
					@include text(mobile);
					line-height: 18px;
					margin-top: 28px;
				}

				.btn_continue_guest {
					@include button(mobile);
				}
			}

			&.edit_setup {
				width: 100vw;

				.btn_close {
					top: 17px;
				}

				h3 {
					@include header(mobile);
				}

				h4,
				.text {
					@include text(mobile);
				}

				.row {
					.time {
						@include text(mobile);
						flex: 0 0 121px;
					}

					.dropdown {
						&.time_small {
							.list {
								top: auto;
								bottom: calc(38px + 4px);
								transform-origin: bottom;
							}
						}
					}
				}

				.btn_save {
					@include button(mobile);
				}
			}

			&.contact_us {
				width: 100%;
				border-radius: 0;
				height: 100vh;
				padding-top: 68px;

				display: flex;
				flex-direction: column;

				overflow-y: auto;

				.btn_close {
					top: 12px;
					right: 17px;
				}

				.wrapper {
					display: flex;
					flex-direction: column-reverse;
					gap: 40px;

					.account_form {
						padding: 0;

						height: 100%;
						display: flex;
						flex-direction: column;

						.input {
							> textarea {
								height: 80px;
							}

							.title {
								@include text(mobile);
							}

							&.message {
								.error {
									display: none;
								}

								&.with_error {
									.error {
										display: block;
									}
								}
							}
						}

						.captcha {
							margin-top: 16px;
							width: auto;
							height: 76px;
							margin-bottom: 16px;
						}

						button[type='submit'] {
							margin-top: auto;
						}
					}

					.info {
						max-width: 351px;

						display: flex;
						flex-direction: column;
						row-gap: 6px;
						column-gap: 19px;

						margin-top: 0;

						a {
							margin-bottom: 0;

							&.phone {
								grid-area: phone;
							}

							&.email {
								grid-area: email;
							}

							&.map {
								grid-area: map;
							}
						}

						&.without_phone {
							grid-template-areas:
								'email map'
								'email map';
						}

						&.without_phone_email {
							display: block;
						}
					}
				}
			}

			&.delivered {
				.btn_continue {
					margin-top: 24px;
				}
			}
		}

		&.contact {
			display: block;
		}

		&.create_account {
			overflow-y: scroll;
			display: block;
		}
	}
}
