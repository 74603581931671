.dashboard {
	.dashboard_list.request_form_list {
		.header_block {
			justify-content: space-between;
			margin-bottom: 16px;

			a {
				margin-right: 1px;
				height: 40px;
			}
		}

		h3 {
			font-size: 18px;
			line-height: 27px;
			margin-bottom: 16px;
		}

		.list {
			gap: 0;

			li[draggable='true'] {
				gap: 8px;
				padding-left: 32px;
				background: url('#{$img-url}/move.svg') 12px center no-repeat;
				cursor: grab;

				&.dragging {
					cursor: grabbing;
				}
			}

			.info {
				gap: 8px;
				max-width: none !important;

				span {
					font-size: 12px;
					line-height: 18px;
					opacity: 50%;
				}

				p {
					font-size: 14px;
					line-height: 21px;

					a {
						color: #14a2e2;
					}
				}

				&.type_note {
					p {
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}
				}
			}

			.actions {
				gap: 8px;
			}
		}

		.block {
			padding-bottom: 40px;

			&:last-child {
				padding-bottom: 0;
			}

			.header_block {
				margin-bottom: 4px;
			}

			& > p:not(.no_items) {
				font-size: 12px;
				line-height: 18px;
				opacity: 50%;
				width: 410px;
				padding-bottom: 16px;
			}
		}

		.checkbox {
			display: flex;
			margin-bottom: 16px;
			margin-left: 16px;
			margin-top: 16px;
			align-items: center;

			input {
				@include checkboxInput(desktop);
				border-color: $accent-color;

				&:checked {
					border-color: $accent-color;
					background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
				}
			}

			label {
				font-size: 14px;
				opacity: 100%;
				padding-bottom: 0;
				padding-left: 8px;
			}
		}
	}

	.dashboard_right_section > .form_item {
		&.long_text_field {
			.input {
				width: 402px;
			}
		}

		.header_block {
			margin-bottom: 40px;
		}

		form {
			.input {
				label {
					opacity: 100%;
					font-size: 14px;
					line-height: 21px;
				}

				input,
				textarea {
					margin-bottom: 40px;
					font-size: 12px;
					line-height: 18px;
				}

				textarea {
					margin-top: 0;
					height: 84px;
				}
			}

			.input_wysiwyg {
				label {
					opacity: 100%;
					font-size: 14px;
					line-height: 21px;
				}

				.rsw-editor {
					margin-bottom: 40px;

					a {
						color: #14a2e2;
					}
				}
			}
		}

		&.radio_field {
			a:not(.icon) {
				font-size: 12px;
				line-height: 18px;
				color: $link-color;
				text-decoration: none;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			.input {
				gap: 4px;
				margin-bottom: 40px;

				div {
					position: relative;

					&:hover {
						.icon {
							display: block;
						}
					}
				}

				.icon {
					display: none;
					position: absolute;
					top: 6px;
					right: 7.5px;
					width: 18px;
					height: 18px;

					&::after,
					&::before {
						position: absolute;
						content: '';
						width: 13px;
						height: 1px;
						top: 9px;
						left: 3px;
						background-color: $accent-color;
					}

					&::after {
						rotate: (45deg);
					}

					&::before {
						rotate: (-45deg);
					}
				}

				label {
					padding-bottom: 0;
				}

				input {
					height: 30px;
					margin-bottom: 0;
				}
			}
		}

		.checkboxes {
			display: flex;
			gap: 40px;
			margin-bottom: 40px;

			.checkbox {
				display: flex;
				align-items: center;

				input {
					@include checkboxInput(desktop);
					border-color: $accent-color;

					&:checked {
						border-color: $accent-color;
						background: $accent-color url('#{$img-url}/checkbox.svg') center center no-repeat;
					}
				}

				label {
					font-size: 14px;
					opacity: 100%;
					padding-bottom: 0;
					padding-left: 8px;
				}
			}
		}
	}
}
