@media print {
	.dashboard {
		display: block;
		height: 100%;

		.sidebar,
		.dashboard_list {
			display: none;
		}

		.dashboard_right_section {
			position: static;
			width: auto;

			.request_item {
				.header_block {
					h2 {
						padding-left: 0;
					}

					.icon {
						display: none;
					}
				}

				dl {
					grid-template-columns: 200px auto;
				}

				.no_print,
				button,
				.button,
				.buttons,
				.actions,
				label,
				textarea,
				.upload_info,
				.files > .description {
					display: none;
				}

				.block.comments li div {
					max-width: none;
				}
			}
		}
	}
}
