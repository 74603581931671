.rsw-editor {
	position: relative;

	.rsw-textarea {
		position: absolute;
		bottom: 0px;
		left: 0px;
		right: 0px;
		width: 100%;
		height: 1px;
		border: 0px;
		padding: 0px;
		margin: 0px;
		min-height: 1px;
	}

	.rsw-icon-link {
		background: url('#{$img-url}/wysiwyg_link.svg') center center no-repeat;
		width: 32px;
		height: 32px;
		display: inline-block;
	}

	.rsw-ce a {
		color: #14a2e2;
	}

	&.readonly {
		pointer-events: none;

		.rsw-ce {
			pointer-events: none;
			user-select: none;
		}

		&::after {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			bottom: 0px;
			content: ' ';
			z-index: 1000;
			pointer-events: all;
		}
	}

	.rsw-btn-link {
		background: transparent;
		border: 0;
		color: #222;
		cursor: pointer;
		font-size: 1em;
		height: 2em;
		outline: none;
		padding: 0;
		width: 2em;
	}
}
