.dropdown {
	position: relative;
	display: inline-block;

	.selection,
	.list,
	.item {
		box-sizing: border-box;
	}

	.selection {
		@include grayText(desktop);
		height: 26px;
		padding: 4px 29px 4px 8px;

		position: relative;

		background-color: $light-gray-color;
		border: 1px solid $border-color;
		border-radius: 6px;
		cursor: pointer;

		&::after {
			content: '';
			width: 8px;
			height: 6px;

			position: absolute;
			top: 9px;
			right: 8px;

			background: url('#{$img-url}/arrow_down_grey.svg') center no-repeat;
			background-size: cover;
		}
	}

	.list {
		width: 100%;
		position: absolute;
		top: calc(26px + 4px);
		max-height: calc(5 * 26px + 2px);
		overflow-y: auto;

		border: 1px solid #14a2e2;
		background-color: #fff;
		border-radius: 6px;

		transform: scaleY(0);
		transform-origin: top;
		transition: ease-in-out 0.3s;

		z-index: 300;

		.item {
			@include grayText(desktop);

			padding: 4px 8px;
			height: 26px;
			background-color: #fff;
			cursor: pointer;

			&:nth-child(even) {
				background-color: $light-gray-color;
			}

			&:first-child,
			&:last-child {
				border-radius: 6px;
			}

			&:hover {
				background-color: $hover-dropdown-color;
			}

			&.chosen {
				color: $accent-color;
			}
		}

		&::-webkit-scrollbar {
			width: 10px;
		}

		&::-webkit-scrollbar-track {
			margin: 5px 0;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $scrollbar-color;
			background-clip: padding-box;
			border: solid 3px rgb(0 0 0 / 0);
			border-radius: 6px;
		}
	}

	&.open {
		.selection {
			border: 1px solid #14a2e2;
			color: $accent-color;

			&::after {
				top: 10px;
				background: url('#{$img-url}/arrow_up_blue.svg') center no-repeat !important;
				background-size: cover;
			}
		}

		.list {
			transform: scaleY(1);
		}
	}

	&.selected {
		.selection {
			color: $accent-color !important;
			background-color: #fff !important;

			&::after {
				background: url('#{$img-url}/arrow_down_blue.svg') center no-repeat;
				background-size: cover;
			}
		}
	}

	&.time,
	&.calendar {
		.selection {
			height: 30px;
			padding: 6px 25px 6px 8px;

			&::after {
				width: 10px;
				height: 8px;
				top: 10px;
				right: 10px;
			}
		}

		.list {
			max-height: calc(5 * 30px + 2px);
			top: calc(30px + 4px);

			.item {
				height: 30px;
				padding: 6px 8px;

				&.chosen {
					color: $accent-color;
				}
			}
		}

		&.open {
			.selection {
				&::after {
					top: 11px;
				}
			}
		}
	}

	&.time_small {
		.selection {
			height: 22px;
			padding: 1px 12px 1px 8px;
			border-radius: 2px;

			&::after {
				width: 7px;
				height: 7px;
				top: 6px;
				right: 7px;
			}
		}

		.list {
			max-height: calc(5 * 22px + 2px);
			top: calc(22px + 4px);
			border-radius: 2px;

			.item {
				height: 22px;
				padding: 1px 12px 1px 8px;
			}
		}

		&.open {
			.selection {
				&::after {
					top: 7px;
				}
			}
		}
	}

	&.disabled {
		pointer-events: none;
	}
}

@media (width <= 767px) {
	.dropdown {
		.selection {
			height: 38px;
			border-radius: 12px;
			font-size: 14px;
			line-height: 21px;
			padding: 8px 40px 8px 10px;

			&::after {
				width: 12px;
				height: 8px;
				top: 15px;
				right: 10px;
			}
		}

		.list {
			border-radius: 12px;
			max-height: calc(5 * 38px + 2px);
			top: calc(38px + 8px);

			.item {
				height: 38px;
				padding: 8px;
				font-size: 14px;
				line-height: 21px;

				&:first-child,
				&:last-child {
					border-radius: 12px;
				}
			}

			&::-webkit-scrollbar {
				width: 14px;
			}

			&::-webkit-scrollbar-track {
				margin: 10px 0;
			}

			&::-webkit-scrollbar-thumb {
				border: solid 5px rgb(0 0 0 / 0);
				border-radius: 12px;
			}
		}

		&.open {
			.selection {
				&::after {
					top: 14px;
				}
			}
		}

		&.time,
		&.calendar {
			.selection {
				@include grayText(mobile);
				height: 44px;
				padding: 11px 30px 11px 12px;

				&::after {
					top: 18px;
					right: 14px;
				}
			}

			.list {
				max-height: calc(5 * 44px + 2px);
				top: calc(44px + 8px);

				.item {
					@include grayText(mobile);
					height: 44px;
					padding: 11px 12px;
				}
			}

			&.open {
				.selection {
					color: $accent-color;

					&::after {
						top: 17px !important;
					}
				}
			}
		}

		&.time_small {
			.selection {
				height: 38px;
				padding: 8px 12px 8px 8px;
				border-radius: 12px;

				&::after {
					width: 12px;
					height: 8px;
					top: 15px;
					right: 13px;
				}
			}

			.list {
				max-height: calc(5 * 38px + 2px);
				top: calc(38px + 4px);
				border-radius: 12px;

				.item {
					height: 38px;
					padding: 8px 12px 8px 8px;
				}
			}

			&.open {
				.selection {
					&::after {
						top: 14px !important;
					}
				}
			}
		}
	}
}
